<template>
  <v-app>
      <!-- FILTROS -->
      <v-card class="notPrint">
        <!-- <v-row v-if="naosincronizados && naosincronizados.length > 0" class="justify-content-center">
          <v-alert
            border="left"
            dismissible
            elevation="6"
            type="info"
            class="m-3"
          >Você possui pedidos sem sincronizar 
          <v-badge :value="naosincronizados.length" :content="naosincronizados.length" :color="'red'" overlap>
            <v-btn color="primary" @click="dialogNaoSincronizados = true">Ver</v-btn>
          </v-badge>
          </v-alert>
        </v-row> -->

        <v-card-title>Filtros</v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="6" md="2">
                <b-form-group label="Data Inicial"><b-form-input type="date" v-model="filtros.dataInicial"></b-form-input></b-form-group>
              </v-col>
              <v-col cols="6" md="2">
                <b-form-group label="Data Final"><b-form-input type="date" v-model="filtros.dataFinal"></b-form-input></b-form-group>
              </v-col>
              <v-col cols="12" md="2" class="my-auto">
                <v-btn color="primary" type="button" @click="buscarPedidos()" :loading="loading">Buscar</v-btn>
              </v-col>
            </v-row>
          </v-container>
      </v-card>
      
      <!-- CARD TABLE COM PEDIDOS -->
      <v-card class="p-3 notPrint">
        <v-data-table dense
        :headers="headers" 
        :items="pedidosComputed" 
        :loading="loading"
        :items-per-page=30
        :search="filtros.search"
        loading-text="Carregando... Por favor aguarde" 
        class="elevation-1"
        
        >
          <template v-slot:top>
              <v-text-field
              v-model="filtros.search"
              label="Filtrar Cliente"
              class="mx-4"
              style="margin-top:25px"
              ></v-text-field>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-chip small :color="getColor(item.status)">{{(item.status == 'digitado' && item.numped != null) ? 'sincronizado' : item.status}}</v-chip>
          </template>
          <template v-slot:[`item.fxcorreta`]="{item}">
            <span>{{ item.vvlrliq <= 2500 ? 'FX1' : item.vvlrliq >= 10100 ? 'FX3' : 'FX2' }}</span>
          </template>
          <!-- BOTÃO VER ITEMS -->
          <template v-slot:[`item.itens`]="items">
              <v-btn x-small color="primary" @click="verItems(items)">Ver Pedido</v-btn>
          </template>

          <!-- LINHA DE TOTAIS -->
          <template slot="body.append">
            <tr>
              <th>Totais</th>
              <th></th>
              <th></th>
              <th>{{Number.parseFloat(totalPesoPrePedidos.vlrliq).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}}</th>
              <th>{{Number.parseFloat(totalPesoPrePedidos.pesliq).toLocaleString('pt-BR',{minimumFractionDigits: 2,maximumFractionDigits:2})}}</th>
              <th>{{Number.parseFloat(totalPesoPrePedidos.pesbru).toLocaleString('pt-BR',{minimumFractionDigits: 2,maximumFractionDigits:2})}}</th>
            </tr>
          </template>>

        </v-data-table>

         <h5> Total Valor Líquido: {{Number.parseFloat(totalPesoPrePedidos.vlrliq).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}}</h5> 
         <h5> Total Peso Líquido: {{Number.parseFloat(totalPesoPrePedidos.pesliq).toLocaleString('pt-BR',{minimumSignificantDigits: 3})}}</h5> 

      </v-card>

      <!-- DIALOG DETALHE PEDIDO -->
      <v-dialog v-model="dialogDetalhes" v-if="detalhesPedido" transition="dialog-bottom-transition">
        <v-card class="text-left printSection">
          <v-card-title class="headline primary" style="color:#fff;">Detalhes Pedido - {{detalhesPedido.destns}}</v-card-title>
          <b-row class="justify-content-center">
            <b-img src="@/assets/logo_xb.png" style="max-width:150px;"></b-img>
          </b-row>
          <b-row class="text-center">
            <b-col>
              <v-btn color="primary" @click="enviarImpressao()">Imprimir Pedido<v-icon>mdi-printer</v-icon></v-btn>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-3">
            <b-col cols="10" class="text-center">
              <h3><strong>Pedido de {{detalhesPedido.destns}}</strong></h3>
              <h5 v-if="detalhesPedido.pedrep">Emissão: {{detalhesPedido.datemi}} - Ordem de Compra: {{detalhesPedido.pedrep}}</h5>
              <h5 v-if="!detalhesPedido.pedrep">Emissão: {{detalhesPedido.datemi}}</h5>
            </b-col>
          </b-row>

          <v-card class="m-1">
            <v-card-text>
              <v-row>
                <v-col cols="1"><v-text-field dense v-model="detalhesPedido.numped" label="Número Pedido Xamego" readonly></v-text-field></v-col>
                <v-col cols="1"><v-text-field dense v-model="detalhesPedido.codcli" label="Código Cliente" readonly></v-text-field></v-col>
                <v-col cols="7"><v-text-field dense v-model="detalhesPedido.nomcli" label="Razão Social" readonly></v-text-field></v-col>
                <v-col cols="3"><v-text-field dense v-model="detalhesPedido.cgccpf" label="CNPJ" readonly></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><v-text-field dense v-model="detalhesPedido.emanfe" label="Email" readonly></v-text-field></v-col>
                <v-col cols="3"><v-text-field dense v-model="detalhesPedido.foncli" label="Telefone" readonly></v-text-field></v-col>
                <v-col cols="3"><v-text-field dense v-model="detalhesPedido.foncl2" label="Telefone 2" readonly></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><v-text-field dense v-model="detalhesPedido.cidcli" label="Cidade" readonly></v-text-field></v-col>
                <v-col cols="1"><v-text-field dense v-model="detalhesPedido.sigufs" label="UF" readonly></v-text-field></v-col>
                <v-col cols="5"><v-text-field dense v-model="detalhesPedido.baicli" label="Bairro" readonly></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="8"><v-text-field dense v-model="detalhesPedido.endcli" label="Endereço" readonly></v-text-field></v-col>
                <v-col cols="2"><v-text-field dense v-model="detalhesPedido.nencli" label="Número" readonly></v-text-field></v-col>
                <v-col cols="2"><v-text-field dense v-model="detalhesPedido.cepcli" label="CEP" readonly></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><v-text-field dense v-model="detalhesPedido.nomrep" label="Representante" readonly></v-text-field></v-col>
                <v-col cols="6"><v-text-field dense v-model="detalhesPedido.itensProduto[0].destpr" label="Tabela de Preço" readonly></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><v-text-field dense v-model="detalhesPedido.descpg" label="Condição de Pagamento" readonly></v-text-field></v-col>
                <v-col cols="6"><v-text-field dense v-model="detalhesPedido.ciffob" label="Frete" readonly></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><v-textarea v-model="detalhesPedido.obsped" label="Observação Pedido" readonly></v-textarea></v-col>
                <v-col cols="6"><v-textarea v-model="detalhesPedido.obsnf" label="Observação Nota Fiscal" readonly></v-textarea></v-col>
              </v-row>
            </v-card-text>
            
          </v-card>

          <v-card class="m-1">
            <v-card-text>
              <v-simple-table v-if="detalhesPedido">
                <thead>
                  <th class="text-center">Cód. Produto</th>
                  <th class="text-center">Descrição</th>
                  <th class="text-center">Qtd</th>
                  <th class="text-center">Peso Líq.</th>
                  <th class="text-center">Peso Bruto</th>
                  <th class="text-center">Valor Tabela</th>
                  <th class="text-center">% Desc. Avista</th>
                  <th class="text-center">% Desc. Campanha</th>
                  <th class="text-center">% Desc. Extra</th>
                  <th class="text-center">% Desc. XTOP</th>
                  <th class="text-center">% Desc. Carga Fechada</th>
                  <th class="text-center">% Desc. Retira na Fábrica</th>
                  <th class="text-center">Valor Uni Líq.</th>
                  <th class="text-center">Valor Total Líq.</th>
                </thead>
                <tbody>
                  <tr v-for="produto in detalhesPedido.itensProduto" :key="produto.codpro" style="page-break-inside:auto;">
                    <td>{{produto.codpro}}</td>
                    <td>{{produto.despro}}</td>
                    <td class="text-right">{{produto.qtdped}}</td>
                    <td class="text-right">{{Number.parseFloat(produto.pesliq * produto.qtdped).toFixed(2).toLocaleString('pt-br')}}</td>
                    <td class="text-right">{{Number.parseFloat(produto.pesbru * produto.qtdped).toFixed(2).toLocaleString('pt-br')}}</td>
                    <!-- <td>{{Number.parseFloat(produto.vlruni).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td> -->
                     <!-- MODELO ANTIGO BANCO DE DADOS -->
                  <td v-show="produto.vlrbru > 0 && !produto.vlruni">{{Number.parseFloat(produto.vlrbru).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                  <td v-show="produto.vlruni && produto.vlrori">{{Number.parseFloat(produto.vlruni).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                  <td v-show="produto.vlruni && !produto.vlrori">{{Number.parseFloat(produto.vlruni).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                  <td v-show="produto.vlrori && !produto.vlruni">{{Number.parseFloat(produto.vlrori).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                  <td class="text-center">{{(produto.desavi ? produto.desavi : 0)}}%</td>
                  <td class="text-center">{{(produto.descam ? produto.descam : 0)}}%</td>
                  <td class="text-center">{{(produto.desext ? produto.desext : 0)}}%</td>
                  <td class="text-center">{{(produto.destop ? produto.destop : 0)}}%</td>
                  <td class="text-center">{{(produto.descar ? produto.descar : 0)}}%</td>
                  <td class="text-center">{{(produto.retfab ? produto.retfab : 0)}}%</td>
                  <td class="text-right">{{Number.parseFloat(produto.vlrliq).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                  <td class="text-right">{{Number.parseFloat(produto.vlrliq * produto.qtdped).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>

                  </tr>
                  <tr>
                    <th>Totais</th>
                    <td></td>
                    <th class="text-right">{{totalQtd}}</th>
                    <th class="text-right">{{Number.parseFloat(totalPesliq).toFixed(2).toLocaleString('pt-br')}}</th>
                    <th class="text-right">{{Number.parseFloat(totalPesbru).toFixed(2).toLocaleString('pt-br')}}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right">{{Number.parseFloat(totalVlrliq).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</th>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          
          </v-card>

        </v-card>
      </v-dialog>

      <!-- DIALOG sincronização-->
      <div>
          <v-dialog v-model="dialogSync" persistent width="550px">
              <v-card>
                  <v-card-title class="headline error" style="color:#fff;">Atenção</v-card-title>
                  <v-card-title class="justify-center">
                  Uma vez sincronizado não será possivel editar o pedido, deseja continuar mesmo assim?</v-card-title>
                  <v-card-text>
                      <v-btn color="primary" class="mx-2" @click="confirmaSincronizacao(true)">Sim</v-btn>
                      <v-btn color="error" class="mx-2" @click="confirmaSincronizacao(false)">Não</v-btn>
                  </v-card-text>
              </v-card>
          </v-dialog>
      </div>

      <!-- DIALOG exclusão-->
      <div>
          <v-dialog v-model="dialogExclusao" persistent width="550px">
              <v-card>
                  <v-card-title class="headline error" style="color:#fff;">Atenção</v-card-title>
                  <v-card-title class="justify-center">
                  Uma vez excluido não será possivel editar o pedido, deseja continuar mesmo assim?</v-card-title>
                  <v-card-text>
                      <v-btn color="primary" class="mx-2" @click="confirmaExclusao(true)">Sim</v-btn>
                      <v-btn color="error" class="mx-2" @click="confirmaExclusao(false)">Não</v-btn>
                  </v-card-text>
              </v-card>
          </v-dialog>
      </div>

      <!-- DIALOG EDIÇÃO NOVO-->
      <v-dialog v-model="dialogEdicao">
        <EditarPedido v-if="edicaoPedido"
          :token="token"
          :status="edicaoPedido.status"
          :cliente="{codtpr: ' '}"
          :pedidoDomain="pedidoDomain"
          :edicaoPedido="edicaoPedido"
          :retiraNaFabrica="edicaoPedido.retiraNaFabrica"
          :key="edicaoPedido._id"
          :condicao="edicaoPedido.codcpg"
          :idProp="edicaoPedido._id"
          @refresh="buscarPedidos()"
          @fecharEdicao="fecharEdicao()"
        />
      </v-dialog>

      <!-- DIALOG QUE MOSTRA PEDIDOS QUE FALTAM SINCRONIZAR -->
      <div>
        <v-dialog v-model="dialogNaoSincronizados" width="80%">
              <v-card>
                  <v-card-title class="headline primary" style="color:#fff;">Pedidos Não Sincronizados</v-card-title>
                  <v-card-text v-for="pedido in naosincronizados" :key="pedido.numped">
                      {{new Date(pedido.datemi).toLocaleDateString('pt-br')}} | {{pedido.nomcli}} | {{pedido.descpg}}
                  </v-card-text>
              </v-card>
        </v-dialog>
      </div>

  </v-app>
</template>

<script>
import {TabelaPreco} from '../../domain/classes/TabelaPreco'
import {Cliente} from '../../domain/classes/Cliente'
import {Produto} from '../../domain/classes/Produto'
import {Pedido} from '../../domain/classes/Pedido'

import EditarPedido from "@/components/pedidos/EditarPedido.vue"
  export default {
    name: 'ConsultarPedido',
    components:{
      EditarPedido,
    },
    
    // props:{
    //     token:null,
    // },

    mounted(){
      if(!this.token){
        this.token = sessionStorage.getItem('token');
      }
      this.buscarNaoSincronizados();
      this.buscarTabelas();
      this.buscarProdutos();
      

    },

    data(){
        return{
            token:null,
            pedido:{
              codtpr: null,
              frete: null,
              pagamento: null
            },
            pedidoDomain: Pedido,
            // tabelasDomain: [],
            tabelaDomain: {},
            cliente:{
              codtpr:null,
            },
            prePedidos:[],
            marcados:[],
            loteSincronizados:[],
            errosLote:[],
            loading:false,
            loadingSync:false,
            dialogSync:false,
            dialogSyncLote:false,
            dialogExclusao:false,
            dialogEdicao:false,
            confirmSync:null,
            sincronizando:false,
            excluindo:null,
            sincronized: false,
            detalhesPedido:null,
            dialogDetalhes:false,
            temp:null,
            filtros:{
              search:"",
              dataInicial: `${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2,'0')}-${String(new Date().getDate()).padStart(2,'0')}`,
              dataFinal:`${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2,'0')}-${String(new Date().getDate()).padStart(2,'0')}`,
              transacao:null,
              transacoes:[
                {text:'Todos',value:null},
                {text:'Pedido de Vendas',value:'90100'},
                {text:'Pedido de Bonificação',value:'90110'},
                {text:'Pedido de Troca',value:'90111'},
                {text:'Pedido de Amostra',value:'90112'},
              ],
              sincronizado:'todos',
              sincronizadoOPT:[
                {text:'Todos',value:'todos'},
                {text:'Sim',value:'sim'},
                {text:'Não',value:'nao'},
              ],
              statusOPT:[
                {text:'Todos',value:null},
                {text:'Digitados',value:'digitado'},
                {text:'Autorizados',value:'autorizado'},
                {text:'Em edição',value:'edicao'},
                {text:'Pendentes',value:'pendente'},
                {text:'Rejeitados',value:'rejeitado'},
              ],
              status: null,
            },
            headers:[
              {text:'Data Emissão', value:'datemi'},
                {text:'Cód. Cliente',value:'codcli'},
                {text:'Razão Social',value:'nomcli'},
                {text:'Valor R$',value:'vlrliq',align:'right'},
                {text:'Peso Líq.',value:'pesliq',align:'right'},
                // {text:'Peso Bruto',value:'pesbru',align:'right'},
                {text:'Descrição Pgto.',value:'descpg'},
                {text:'Descrição Tipo',value:'destns'},
                {text:'Pedido Senior',value:'numped'},
                {text:'Faixa Correta',value:'fxcorreta'},
                {text:'Faixa',value:'faixa'},
                {text:'Status',value:'status'},
                {text:'Representante',value:'nomrep'},
                {text:'Itens',value:'itens'},
                // {text:'Sincronizar',value:'sync'},
                // {text:'Excluir',value:'excluir'},
                // {text:'Editar',value:'editar'},
                // {text:'Representante',value:'codrep'}
                // {text:'Marcar',value:'marcar'}
                // {text:'Núm. Pedido',value:'_id',align:'start'},
                // {text:'Cód. Representante',value:'codrep'},
                // {text:'Nome Representante',value:'nomrep'},
                // {text:'Condição Pgto.',value:'codcpg'},
                // {text:'Tipo Pedido',value:'tnspro'},
            ],
            edicaoPedido: null,
            naosincronizados:[],
            dialogNaoSincronizados: false,
            tabelas: [],
            produtos: [],
        }
    },

    methods:{

      enviarImpressao(){
        sessionStorage.setItem('pedido',JSON.stringify(this.detalhesPedido));
        this.$router.push('/pre-pedidos/imprimir');
      },

      excluirPedido(pedido){
        //sinaliza para o botão que o item está sendo excluido
        this.excluindo = pedido.item._id;

        let pedidoFull = this.prePedidos.find(p => p._id == pedido.item._id);
        if(pedidoFull){
          this.$axios.delete(`/pedidos/excluir/${pedidoFull._id}`,{headers:{'Authorization':`Bearer ${this.token}`}})
          .then(result =>{
            let pedidoExcluido = result.data;
            console.log(pedidoExcluido);
            alert('Pedido Excluido com Sucesso');
            this.buscarPedidos();
          })
          .catch(err => {
            alert(`Pedido Não excluido! Motivo: ${err.response.data}`);
            console.log(err);
            this.sincronizando = false;
          })
        }
      },

      editarPedido(pedido){
        this.edicaoPedido = null;
        let codtpr = pedido.item.itensProduto[0].codtpr;
        let destpr = pedido.item.itensProduto[0].destpr;

        // this.edicaoPedido = pedido.item;
        
        let produtosBuscados = [];
        this.pedidoDomain = null;
        if(codtpr){
          this.tabelaDomain = new TabelaPreco(codtpr,destpr);
          this.$axios.get(`/tabelas/produtos/${codtpr}`,{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp => {
              produtosBuscados = resp.data;
              produtosBuscados.forEach(p => {
                let produto = new Produto(p.codpro,p.despro,p.prebas,p.pesliq,0,p.pesbru);
                  produto.tolmen = p.tolmen;
                  produto.totpalete = p.usu_qtdtot;
                  produto.codICMSSubst = p.codtst;
                  this.tabelaDomain.adicionarProduto(produto);

              
              });
              this.edicaoPedido = pedido.item;
              this.pedidoDomain = new Pedido(new Cliente(this.edicaoPedido.codcli,this.edicaoPedido.nomcli,this.edicaoPedido.cgccpf,this.edicaoPedido.sigufs,this.edicaoPedido.cidcli),this.tabelaDomain)
              this.dialogEdicao = true;
              // this.pedidoDomain.tabela.produtos = produtosTabela;
              // this.pedidoDomain.produtos = pedido.item.itensProduto;

            })
            .catch(err =>{
                console.log(err);
                alert('Não foi possível encontrar Produtos')
            })
        }
        
      },

      atualizarAtividade(id){
        console.log('id',id)
        this.$axios.post('/atividades/finalizar',{'idAtividade':id},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
        .then(() => console.log('Atividade Atualizada')).catch(err => console.log(err));
      },

      sincronizarPedido(pedido){
        //sinaliza para o botão que o item está sendo sincronizado
        
        this.sincronizando = true;
        let pedidoFull = this.prePedidos.find(p => p._id == pedido.item._id);
        pedidoFull.codCli = pedidoFull.codcli;
        pedidoFull.cifFob = pedidoFull.ciffob;
        pedidoFull.codTns = pedidoFull.tnspro;
        pedidoFull.codCpg = pedidoFull.codcpg;
        pedidoFull.itensProduto.map(i => {
          i.codPro = i.codpro;
            i.codDer = 'PADRAO';
            i.qtdPed = i.qtdped;
            i.preUni = i.vlrliq;
            i.codTpr = i.codtpr;
          return { i }
        })

        if(pedidoFull){
          
          
          this.$axios.put('/pedidos/sincronizarput',pedidoFull,{headers:{'Authorization':`Bearer ${this.token}`}})
          .then(result =>{
            let pedidoSincronizado = result.data;
            this.sincronizando = false;
            console.log(pedidoSincronizado);
            alert('Sincronização Concluída com Sucesso!');
            this.atualizarAtividade(pedidoFull.atividade);
            this.buscarPedidos();
          })
          .catch(err => {
            alert(`Pedido Não Sincronizado! Motivo: ${err.response.data}`);
            this.sincronizando = false;
          })
          
        }
        
      },

      confirmaSincronizacao(confirmacao){
        if(confirmacao){
          this.sincronizarPedido(this.temp);
          this.temp = null;
        }else{
          this.temp = null;
        }
        this.dialogSync = false;
      },


      confirmaExclusao(confirmacao){
        if(confirmacao){
          this.excluirPedido(this.temp);
          this.temp = null;
        }else{
          this.temp = null;
        }
        this.dialogExclusao = false;
      },

      mostraConfirmacao(pedido){
        this.temp = pedido;
        this.dialogSync = true;
      },

      mostraConfirmacaoExclusao(pedido){
        this.temp = pedido;
        this.dialogExclusao = true;
      },

      verItems(pedido){
        this.detalhesPedido = pedido.item;
        if(!this.detalhesPedido.itensProduto[0].destpr){
          let tabela = this.tabelas.find(t => t.value == this.detalhesPedido.itensProduto[0].codtpr);
          if(tabela){
            this.detalhesPedido.itensProduto[0].destpr = tabela.text;
          }
        }
        this.dialogDetalhes = true;
      },

      fecharEdicao(){
        this.dialogEdicao = false;
      },
      
      buscarPedidos(){

        this.loading = true;
        if(!this.filtros.dataInicial || !this.filtros.dataFinal){
          alert('Primeiro selecione as datas');
        }else{

          this.$axios.get(`/pedidos/pre-pedidos/?datini=${this.filtros.dataInicial}&datfin=${this.filtros.dataFinal}`,{headers:{'Authorization':`Bearer ${this.token}`}})
          .then(result => {
            this.prePedidos = result.data
            for (let p of this.prePedidos) {
                p.destns = p.tnspro == '90100' ? 'Venda' : p.tnspro == '90110' ? 'Bonificação' : p.tnspro == '90111' ? 'Troca' : 'Amostra';
                p.datemi = new Date(p.datemi).toLocaleDateString('pt-br');
                let vlrLiq = 0;
                let pesLiq = 0;
                let pesBru = 0;
                p.status = p.status ? p.status : 'digitado';

                for (const i of p.itensProduto) {
                  vlrLiq += (i.vlrliq * i.qtdped);
                  pesLiq += (i.pesliq * i.qtdped);
                  pesBru += (i.pesbru * i.qtdped);
                }
                p.vvlrliq = Number.parseFloat(vlrLiq);
                p.vlrliq = Number.parseFloat(vlrLiq).toLocaleString('pt-br',{style:'currency',currency:'BRL'});
                p.pesliq = Number.parseFloat(pesLiq).toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2});
                p.pesbru = Number.parseFloat(pesBru).toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2});
            }
            this.loading = false;
          })
          .catch(err => {
              if(err.response.status == 401){
                alert('Sessão Expirada');
                sessionStorage.clear();
                this.$router.push('/');
                this.$router.go();
                console.error(err);
              }else{
                console.log(err);
                alert('Erro ao buscar Pedidos');
              }
          })

        }
      },

      buscarNaoSincronizados(){
        this.$axios.get(`/pedidos/naosincronizados/`,{headers:{'Authorization':`Bearer ${this.token}`}})
          .then(result => {
            this.naosincronizados = result.data;
          }).catch(err => {
            console.log(err);
          })
      },

      buscarTabelas(){
        this.$axios.get('/tabelas/tabelas',{headers:{'Authorization':`Bearer ${this.token}`}})
        .then(resp => {
            this.tabelas = resp.data.map(t => {
                return {
                    text:t.codtpr+' - '+t.destpr,
                    value:t.codtpr,
                    estrategico:t.usu_cliest
                }
            });
        })
        .catch(err =>{
            console.log(err);
            alert('Não foi possível encontrar tabelas')
        })
      },

      buscarProdutos(){
        this.$axios.get(`/produtos/`,{headers:{'Authorization':`Bearer ${this.token}`}})
        .then(resp => {
            this.produtos = resp.data;
        })
        .catch(err =>{
            console.log(err);
            alert('Não foi possível encontrar Produtos')
        })
      },

      getColor(status){
        if(status == 'pendente'){
          return 'deep-orange';
        }else if(status == 'rejeitado'){
          return 'error';
        }else if(status == 'edicao'){
          return 'yellow';
        }else if(status == 'autorizado'){
          return 'green darken-4';
        }else{
          return 'success';
        }
      },

      solicitarAprovacao(pedido){
        this.$axios.patch(`/pedidos/solicitar-autorizacao/${pedido.item._id}`,{id:pedido._id},{headers:{'Authorization':`Bearer ${this.token}`}})
        .then(resp =>{
          console.log(resp.data);
          alert('Solicitação de aprovação disparada');
          this.buscarPedidos();
        }).catch(err =>{
          alert(`Não foi possível solicitar aprovação! Motivo: ${err.response.data}`)
        })
      }

    },

    computed:{
      usuarioComputed(){
        if(this.token){

          let usuario = JSON.parse(atob(this.token.split('.')[1]));
          return usuario
        }else{
          return null;
        }
      },
      pedidosComputed(){
        let pedidos = this.prePedidos.map(p => {
          p.nomcli = p.nomcli.substring(0,17);
          p.nomrep = p.nomrep.substring(0,13);
          p.descpg = p.descpg.substring(0,17);
          return p;
        });
        // if(this.filtros.sincronizado == 'sim'){
        //  pedidos = pedidos.filter(p => p.numped);
        // }else if(this.filtros.sincronizado == 'nao'){
        //  pedidos = pedidos.filter(p => !p.numped);
        // }
        pedidos = pedidos.filter(p => p.tnspro == '90100' || p.tnspro == '90110');

        pedidos = pedidos.filter(p => p.status == 'autorizado' || p.status == 'pendente');

        return pedidos;
      },

      totalQtd(){
        let pedidos = this.detalhesPedido;
        let totalQtd = 0;
        for (const i of pedidos.itensProduto) {
          totalQtd += i.qtdped;
        }
        return totalQtd;
      },

      totalVlrbru(){
        let pedidos = this.detalhesPedido;
        let vlrBru = 0;
        for (const i of pedidos.itensProduto) {
          vlrBru += i.vlrbru;
        }
        return vlrBru;
      },

      totalVlrliq(){
        let pedidos = this.detalhesPedido;
        let vlrLiq = 0;
        for (const i of pedidos.itensProduto) {
          vlrLiq += (i.vlrliq * i.qtdped);
        }
        return vlrLiq;
      },

      totalPesliq(){
        let pedidos = this.detalhesPedido;
        let pesLiq = 0;
        for (const i of pedidos.itensProduto) {
          pesLiq += (i.pesliq * i.qtdped);
        }
        return pesLiq;
      },

      totalPesbru(){
        let pedidos = this.detalhesPedido;
        let pesBru = 0;
        for (const i of pedidos.itensProduto) {
          pesBru += (i.pesbru * i.qtdped);
        }
        return pesBru;
      },

      totalPesoPrePedidos(){
        let BD = require('js-big-decimal');
        let totalpeso ="0";
        let totalvlr ="0";
        let totalpesoBruto = "0"
        for (const i of this.pedidosComputed) {
          totalpeso = BD.add(totalpeso,i.pesliq.replace(".","").replace(",","."));
          totalpesoBruto = BD.add(totalpesoBruto,i.pesbru.replace(".","").replace(",","."));
          for (const j of i.itensProduto) {
            
            totalvlr = BD.add(totalvlr, BD.multiply(Number.parseFloat(j.vlrliq).toFixed(2), Number.parseFloat(j.qtdped)));
            // console.log(totalvlr)
          }
        }
        return {pesliq:totalpeso,vlrliq:totalvlr,pesbru:totalpesoBruto};
      }

    }
  }
</script>

<style scoped  lang = "scss">
// .v-data-table::v-deep th {
//   font-size: 14px !important;
// }
.v-data-table::v-deep td {
  font-size: 0.75rem !important;
}

</style>