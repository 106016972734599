<template>
  <div>
    <!-- <Header /> -->
    <AtividadesComponent :tkn="token" />
  </div>
</template>

<script>
import AtividadesComponent  from '@/components/Atividades_Component.vue'
//import Agenda from '@/components/Agenda.vue'

// import Header from '@/components/Header.vue'
export default {
  data(){
    return{
      token:'',
    }
  },
  components: {
    AtividadesComponent,
    //Agenda,

    // Header
  },
  mounted(){
      this.obterToken();
  },
  methods:{
      obterToken(){
          let token = sessionStorage.getItem('token');
          if(!token){
              this.$router.push('/');
              this.$router.go();
          }else{
            this.token = token;
          }
      }
  }

}
</script>

<style>

</style>