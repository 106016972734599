<template>
    <div>
        <v-row dense style="page-break-inside: avoid;">
            <v-col >
                <v-card height="100%" class="d-flex align-items-center m-1">
                    <v-card-text class="text-center">
                    <h4>Acompanhamento de faturamento (KG) - Representante Mês a Mês</h4>

                    <div class="text-center">
                        <small>Não inclui amostras,trocas e bonificações, abate devoluções</small>
                        <v-divider></v-divider>
                            
                            <v-btn small color="primary" @click="acumulado = !acumulado" class="ml-3">
                                Visão: {{acumulado ? 'Mês a Mês':'Acumulado'}}
                                <v-icon dense class="m-1">{{acumulado ? 'mdi-chart-bell-curve' : 'mdi-chart-bell-curve-cumulative'}}</v-icon> 
                            </v-btn>

                            <v-row class="mt-3">
                                <v-col cols="12" md="3">
                                    <v-select @change="obterDadosFaturadoRep()" v-model="escolhidos" label="Escolha aqui o representante desejado" :items="representantes" hint="Escolha o representante para acompanhamento"></v-select>
                                </v-col>
                                <v-col cols="12" md="9">
                                    <v-card flat :loading="loading">
                                        <LineChartX :chart-data="graphDataComputed" :chart-options="chartOptions"/>
                                    </v-card>
                                </v-col>
                            </v-row>
                    </div>
                    <hr>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>        

        <!-- TOP 20 MAIORES REPRESENTANTES -->
        <v-row dense style="page-break-inside: avoid;">
            <v-col>
                <v-card height="100%" class="d-flex align-items-center m-1">
                    <v-card-text class="text-center">
                    <h4>Ranking REPRESENTANTES (KG) Últimos 12 meses</h4>
                    <small>Não inclui amostras,trocas e bonificações e contempla os últimos 12 meses. Não inclui mês atual.</small>
                        <v-data-table
                            :loading="maioresRepresentantes.length == 0"
                            dense
                            sortBy="rep"
                            sortDesc=true
                            :headers="[
                                {text:'Código Representante',value:'codrep'},
                                {text:'Nome Representante',value:'nomrep'},
                                {text:'Situação',value:'sitrep'},
                                {text:'Data Cadastro',value:'datcad'},
                                {text:'Peso Líquido (KG)',value:'pesliq', align:'right'},
                                {text:'% Representatividade',value:'rep', align:'right'},
                            ]" 
                            :items="maioresRepresentantes.representantes"></v-data-table>
                    <hr>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { Line as LineChartX } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale,LineElement, PointElement,ArcElement,Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale,PointElement,ArcElement,Filler);
export default {
    props:{
        token:String
    },
    components:{
        LineChartX,
    },
    mounted(){
        this.$nextTick(()=>{
            this.obterRepresentantes();
            this.obterMaioresRepresentantes();
        })
    },
    data(){
        return{
            maioresRepresentantes:[],
            faturadoRep:[],
            escolhidos:[],
            representantes:[],
            loading:false,
            acumulado:false,
            chartOptions:{
                responsive: true, 
                maintainAspectRatio: false,
                showAllTooltips: true,
                legend:{
                    display:true,
                    position:'bottom'
                },
            },
        }
    },
    methods:{
        
        obterRepresentantes(){
            this.$axios.get(`/representantes`,{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp =>{
                let representantes = resp.data;
                this.representantes = representantes.map(p =>  {
                    return {value:p.codrep,text:`${p.codrep} - ${p.nomrep}`}
                })
            }).catch(err => {
                alert('Não foi possivel obter os representantes');
                console.log(err);
            })
        },
        obterDadosFaturadoRep(){
            if(this.escolhidos){
                this.loading = true;
                this.$axios.post('/relatorios/representantes',{representantes:[this.escolhidos]},{headers:{'Authorization':`Bearer ${this.token}`}})
                .then(resp => {
                    this.faturadoRep = resp.data;
                    this.loading = false;
                }).catch(err =>{
                    alert('Não foi possível buscar produtos');
                    console.log(err);
                })
            }else{
                alert('Escolha um produto antes')
            }
        },
        obterMaioresRepresentantes(){
        this.$axios.get('/relatorios/maiores-representantes',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp => {
                this.maioresRepresentantes = resp.data;
                let pesliq = Number.parseFloat(this.maioresRepresentantes.anual.Peso - this.maioresRepresentantes.devolvido.xpliq);
                this.maioresRepresentantes.representantes.map(p => {
                    let devolucao = p.devolucao ? p.devolucao : 0;
                    p.pesliq = Number.parseFloat(p.Peso - devolucao).toLocaleString('pt-BR',{minimumFractionDigits:0,maximumFractionDigits:0});
                    p.rep = Number.parseFloat((Number.parseFloat(p.Peso - devolucao) / pesliq)*100).toFixed(2)
                    p.datcad = new Date(p.datcad.replace(/-/g,'/').replace(/T.+/,'')).toLocaleDateString('pt-br')
                    return p;
                })
            })
            .catch(err => {
                alert('Não foi possível obter os representantes mais comprados');
                console.log(err);
            })
        },
    },
    computed:{
        
        graphDataComputed(){
            let labels = ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'];
            
            let acumulado = 0;
            let acumuladoAnoAnterior = 0;
            let datasetMesAMes = [];
            let datasetMesAMesAnoAnterior = [];
            let datasetAcumulado = [];
            let datasetAcumuladoAnoAnterior = [];

            let historicoAnoAtual = this.faturadoRep.filter(h => h.ano == new Date().getFullYear());
            let historicoAnoAnterior = this.faturadoRep.filter(h => h.ano == new Date().getFullYear() - 1);

            for (let i = 1; i <= 12; i++) {
                let mesAtual = historicoAnoAtual.filter(d => d.mes == i);
                if(mesAtual.length > 0){
                    let calc = mesAtual.reduce((a,x) => a + x.Peso,0) - mesAtual.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMes.push(calc);
                    acumulado += calc;
                }else{
                    datasetMesAMes.push(0);
                }
                datasetAcumulado.push(acumulado);

                let mesAtualAnoAnterior = historicoAnoAnterior.filter(d => d.mes == i);
                if(mesAtualAnoAnterior.length > 0){
                    let calc = mesAtualAnoAnterior.reduce((a,x) => a + x.Peso, 0) - mesAtualAnoAnterior.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMesAnoAnterior.push(calc);
                    acumuladoAnoAnterior += calc;
                }else{
                    datasetMesAMesAnoAnterior.push(0);
                }
                datasetAcumuladoAnoAnterior.push(acumuladoAnoAnterior);

            }

            return {
                acumulado: this.acumulado,
                labels: labels,
                datasets:[
                    {
                        backgroundColor: "rgba(1, 116, 188, 0.50)",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        label: new Date().getFullYear(),
                        data: this.acumulado ? datasetAcumulado : datasetMesAMes,
                        fill:true,
                        tension:0.3
                    },
                    {
                        backgroundColor: "rgba(188, 116, 1, 0.50)",
                        borderColor: "rgba(188, 116, 1, 0.50)",
                        label:new Date().getFullYear() -1,
                        data: this.acumulado ? datasetAcumuladoAnoAnterior : datasetMesAMesAnoAnterior,
                        fill: true,
                        tension: 0.3
                    }
                ]
            }

        }
    }
}
</script>

<style>

</style>