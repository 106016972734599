<template>
    <v-card height="100%" class="d-flex align-items-center m-1" :loading="loading">
        <v-card-text class="text-center">
        <!-- <h2>{{reativados ? reativados.qtd : 0}}</h2> -->
        <h4>Clientes Reativados</h4>
        <v-divider></v-divider>
        <div v-if="!loading">
            <p><strong>{{reativados.mesRetrasado.info.mes}}/{{reativados.mesRetrasado.info.ano}}</strong>: {{reativados.mesRetrasado.dados.length}}</p>
            <p><strong>{{reativados.mesPassado.info.mes}}/{{reativados.mesPassado.info.ano}}</strong>: {{reativados.mesPassado.dados.length}}</p>
            <p><strong>{{reativados.mesAtual.info.mes}}/{{reativados.mesAtual.info.ano}}</strong>: {{reativados.mesAtual.dados.length}} </p>
        </div>
        <v-btn color="primary" small @click="dialogs.clientesReativados = true">Detalhar
            <v-icon dense class="m-1">mdi-list-status</v-icon>
        </v-btn>
        </v-card-text>
    
        <!-- DIALOG CLIENTES REATIVADOS -->
        <v-dialog v-model="dialogs.clientesReativados">
            <v-card>
                <v-btn fab x-small color="secondary" class="m-1" dark @click="dialogs.clientesReativados = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text>
                    <v-tabs v-model="tab">
                        <v-tab>
                            Clientes Reativados Mês Atual
                        </v-tab>
                        <v-tab>
                            Clientes Reativados Mês Passado
                        </v-tab>
                        <v-tab>
                            Clientes Reativados Mês Anterior
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Razão Social</th>
                                        <th>Representante</th>
                                        <th>Ultima NF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="cliente in reativados.mesAtual.dados" :key="cliente.CODCLI">
                                        <td>{{ cliente.CODCLI }}</td>
                                        <td>{{ cliente.nomcli }}</td>
                                        <td>{{ cliente.nomrep }}</td>
                                        <td>{{ new Date(cliente.LAST_NF.split('T')[0].split('-').map(n => Number(n))).toLocaleDateString('pt-br') }}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th><th></th><th></th><th></th><th>{{ reativados.mesAtual.dados.length }}</th>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-tab-item>

                        <v-tab-item>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Razão Social</th>
                                        <th>Representante</th>
                                        <th>Ultima NF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="cliente in reativados.mesPassado.dados" :key="cliente.CODCLI">
                                        <td>{{ cliente.CODCLI }}</td>
                                        <td>{{ cliente.nomcli }}</td>
                                        <td>{{ cliente.nomrep }}</td>
                                        <td>{{ new Date(cliente.LAST_NF.split('T')[0].split('-').map(n => Number(n))).toLocaleDateString('pt-br') }}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th><th></th><th></th><th></th><th>{{ reativados.mesPassado.dados.length }}</th>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-tab-item>

                        <v-tab-item>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Razão Social</th>
                                        <th>Representante</th>
                                        <th>Ultima NF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="cliente in reativados.mesRetrasado.dados" :key="cliente.CODCLI">
                                        <td>{{ cliente.CODCLI }}</td>
                                        <td>{{ cliente.nomcli }}</td>
                                        <td>{{ cliente.nomrep }}</td>
                                        <td>{{ new Date(cliente.LAST_NF.split('T')[0].split('-').map(n => Number(n))).toLocaleDateString('pt-br') }}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th><th></th><th></th><th></th><th>{{ reativados.mesRetrasado.dados.length }}</th>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    props:{
        token:String
    },
    mounted(){
        this.$nextTick(() =>{
            if(this.token){
                this.obterClientesReativados();
            }
        })
    },
    data(){
        return{
            tab:null,
            loading: false,
            dialogs:{
                clientesReativados:false
            },
            reativados: {
                mesAtual:{
                    info:[],
                    dados:{},
                },
                mesPassado:{
                    info:[],
                    dados:{},
                },
                mesRetrasado:{
                    info:[],
                    dados:{},
                },
            },
        }
    },
    methods:{
    obterClientesReativados(){
      this.loading = true;
      this.$axios.get('/relatorios/clientes-reativados',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp =>{
        this.reativados = resp.data;
        this.loading = false;
      })
      .catch(err =>{
          if(err.response){
              if(err.response.status == 401){
                  alert('Sessão expirada');
                  sessionStorage.clear();
                  this.$router.push('/login');
              }else{
                    alert('não foi possível buscar clientes reativados');
                    console.log(err);
              }
            }else{
                alert('não foi possível buscar clientes reativados');
                console.log(err);
                this.loading = false;
          }
      })
    },
    }
}
</script>

<style>

</style>