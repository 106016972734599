<template>
  <div>
    
      <v-card>
          <!-- <div style="background-color:#ddd;padding:15px;border-radius:5px;">
            <b-card-text class="text-left">Olá <strong>{{usuario.representacao}}</strong></b-card-text>
          </div> -->
          <!-- <img src="@/assets/logo_xb.png" alt="logo" id="logoimg" style="padding:15px;width:20vw;height:auto;"> -->
          <v-card-title><strong>Nova Atividade</strong></v-card-title>
          
          <b-form @submit="salvarAtividade"> 
            
            <v-container>
                <v-card>
                    <v-tabs v-model="tab">
                        <v-tab>Buscar Cliente</v-tab>
                        <v-tab-item title="Buscar Cliente">
                            <FiltroClientes :cliente="cliente" :clienteFiltrado="clienteFiltradoComputed" @escolheCliente="escolheCliente"/>
                            <v-card-text  v-if="clienteFiltradoComputed != null">
                                <v-btn color="primary" type="button" @click="dialogDetalhes = true">Ver Cliente</v-btn>
                                <v-row dense class="mt-3">
                                    <v-col>
                                        <v-text-field dense outlined label="Nome Contato" v-model="atividade.cliente.nomeContato"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field dense outlined label="Telefone Contato" v-model="atividade.cliente.telContato"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <!-- <v-btn small outlined color="primary" class="m-3" v-b-modal.detalhe v-if="clienteFiltrado != null">Ver Cliente</v-btn> -->
                            
                        </v-tab-item>
                        
                        <v-tab>Novo Cliente</v-tab>
                        <v-tab-item title="Novo Cliente">

                            <v-row>
                                <v-col>
                                    <v-card v-if="clienteFiltradoComputed == null">
                                    <v-card-title>Adicionar Cliente</v-card-title>
                                    <v-card-text>Aqui você insere os dados caso o cliente não esteja em sua carteira
                                        <hr>
                                        <div class="text-left">
                                            <b-container>
                                                <v-row>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field outlined dense label="CNPJ" v-model="atividade.cliente.cnpj"></v-text-field>
                                                        <v-text-field outlined dense label="Razão Social" v-model="atividade.cliente.nomcli"></v-text-field>
                                                        <v-text-field outlined dense label="Nome Fantasia" v-model="atividade.cliente.apecli"></v-text-field>
                                                        <v-text-field outlined dense label="Telefone" v-model="atividade.cliente.foncli"></v-text-field>
                                                        <v-text-field outlined dense label="Email" v-model="atividade.cliente.intnet"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field outlined dense label="Email Documentos Eletrônicos" v-model="atividade.cliente.emanfe"></v-text-field>
                                                        <v-text-field outlined dense label="Cidade" v-model="atividade.cliente.cidcli"></v-text-field>
                                                        <v-text-field outlined dense label="UF" v-model="atividade.cliente.sigufs"></v-text-field>
                                                        <v-text-field outlined dense label="Nome Contato" v-model="atividade.cliente.nomeContato"></v-text-field>
                                                        <v-text-field outlined dense label="Telefone Contato" v-model="atividade.cliente.telContato"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </b-container>
                                        </div>
                                    </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-tab-item>

                        <v-tab>Importar Cliente SAC</v-tab>
                        <v-tab-item title="Importar Cliente SAC">
                        
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <v-text-field dense outlined label="Importar protocolo do SAC" v-model="protocolo" placeholder="Número do protocolo"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn small outlined color="primary" type="button" @click="importarProtocolo()">Importar Protocolo SAC</v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-card v-if="clienteFiltradoComputed == null">
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" lg="6">
                                                    <v-text-field dense outlined label="CNPJ" v-model="atividade.cliente.cnpj"></v-text-field>
                                                    <v-text-field dense outlined label="Razão Social" v-model="atividade.cliente.nomcli"></v-text-field>
                                                    <v-text-field dense outlined label="Nome Fantasia" v-model="atividade.cliente.apecli"></v-text-field>
                                                    <v-text-field dense outlined label="Telefone" v-model="atividade.cliente.foncli"></v-text-field>
                                                    <v-text-field dense outlined label="Email" v-model="atividade.cliente.intnet"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <v-text-field dense outlined label="Email Documentos Eletrônicos" v-model="atividade.cliente.emanfe"></v-text-field>
                                                    <v-text-field dense outlined label="Cidade" v-model="atividade.cliente.cidcli"></v-text-field>
                                                    <v-text-field dense outlined label="UF" v-model="atividade.cliente.sigufs"></v-text-field>
                                                    <v-text-field dense outlined label="Nome Contato" v-model="atividade.cliente.nomeContato"></v-text-field>
                                                    <v-text-field dense outlined label="Telefone Contato" v-model="atividade.cliente.telContato"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                </v-card>
                                </v-col>
                            </v-row>
                        
                        </v-tab-item>
                        
                    </v-tabs>

                </v-card>
            </v-container>

            <!-- DATA ATIVIDADE E CANAL ATIVIDADE -->
            <v-container>
                <v-row class="mt-3">
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>Data Atividade</v-card-title>
                            <v-card-text>
                                <v-text-field dense outlined type="date" v-model="atividade.data" :min="ontem" :max="hoje" required v-if="usuario.role == 'representante'"></v-text-field>
                                <v-text-field dense outlined type="date" v-model="atividade.data" :min="ontem" required v-if="usuario.role != 'representante'"></v-text-field>
                            </v-card-text>
                            <!-- <b-form-group>
                                <div v-if="usuario.role == 'representante'">
                                    <b-form-input type="date" v-model="atividade.data" :min="ontem" :max="hoje" required></b-form-input>
                                </div>

                                <div v-if="usuario.role != 'representante'">
                                    <b-form-input type="date" v-model="atividade.data" :min="ontem" required></b-form-input>
                                </div>
                            </b-form-group> -->
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-title>Canal Atividade</v-card-title>
                            <v-card-text>
                                <div class="text-left">
                                    <b-form-group label="" >
                                    <b-form-radio v-model="atividade.canal" name="canal" value="whatsapp" required>Whatsapp</b-form-radio>
                                    <b-form-radio v-model="atividade.canal" name="canal" value="telefone" required>Telefone</b-form-radio>
                                    <b-form-radio v-show="usuario.role == 'representante'" v-model="atividade.canal" name="canal" value="presencial" required>Presencial</b-form-radio>
                                    <b-form-radio v-model="atividade.canal" name="canal" value="email" required>E-mail</b-form-radio>
                                    </b-form-group>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            
            <!-- TIPO CONTATO -->
            <b-container class="mt-3">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>Tipo Contato</v-card-title>
                            <v-card-text>
                                <p>Aqui você insere qual foi o tipo de contato</p> 
                                <v-row>
                                    <v-col md>

                                            <div v-if="this.usuario.role == 'representante'">
                                                <v-autocomplete dense outlined v-model="atividade.tipo" :items="['Venda Representante']"
                                                @change="limpaPeso()" label="Tipo Atividade" required></v-autocomplete>
                                            </div>
                                            <div v-if="this.usuario.role != 'representante'">
                                                <v-autocomplete dense outlined v-model="atividade.tipo" :items="[
                                                'Agend: Cliente irá retornar a ligação',
                                                'Agend: Deseja efetuar cadastro',
                                                'Agend: Enviado cópia do pedido',
                                                'Agend: Enviado e-mail com orçamento',
                                                'Agend: Informação andamento do pedido',
                                                'Agend: Não se encontra',
                                                'Agend: Retornar depois',
                                                'Agend: Solicita segunda via de boleto',
                                                'Agend: Solicitou Troca',
                                                'Agend:Solicita explicação de produtos',
                                                'Insucesso: Caixa Postal',
                                                'Insucesso: Ligação Caiu',
                                                'Insucesso: Ligação não atendida',
                                                'Insucesso: Linha Muda',
                                                'Insucesso: Número inválido/inexistente',
                                                'Orcamento enviado por WhatsApp',
                                                'Recusa - Mudou Ramo de Atividade',
                                                'Recusa: Achou caro',
                                                'Recusa: Cancelamento de pedido',
                                                'Recusa: Cli é atendido pelo vendedor',
                                                'Recusa: Comprou de outro fornecedor',
                                                'Recusa: Inadimplente. Não vender',
                                                'Recusa: Inativo',
                                                'Recusa: Não possui mais a empresa',
                                                'Recusa: Possui estoque',
                                                'Recusa: Prefere atendimento presencial',
                                                'Recusa: XB não possui o prod solicitado',
                                                'Sem contato',
                                                'Venda',
                                                'Recusa',
                                                'Solicitação Orcamento',
                                                'Compra por outro CNPJ',
                                                'Insatisfeito com a Xamego Bom']"  @change="limpaPeso()" required></v-autocomplete>
                                            </div>

                                            <div class="jumbotron text-left" v-if="atividade.peso > 0">
                                                <p><strong>Peso Total Pedido (kg):</strong> {{Number(atividade.peso).toLocaleString('pt-BR')}}</p>
                                                <p><strong>Valor Total Pedido (R$):</strong> {{Number(atividade.valor).toLocaleString('pt-BR')}}</p>
                                                <hr>
                                                <p><strong>Peso Produtos XB (kg):</strong> {{Number(atividade.pesoXB).toLocaleString('pt-BR')}}</p>
                                                <p><strong>Peso Produtos Itaperuna (kg):</strong> {{Number(atividade.pesoItaperuna).toLocaleString('pt-BR')}}</p>
                                            </div>                           
                                    </v-col>
                                    <v-col md>
                                        <div v-if="atividade.tipo">
                                            <div v-if="atividade.tipo.includes('Venda')">
                                                <div v-if="atividade.cliente.cnpj">

                                                    <v-text-field dense outlined type="number" v-model="atividade.pedido" label="Nº Pedido"></v-text-field>
                                                    <v-btn small outlined color="primary" type="button" @click="pesoValorPedido()">Buscar Dados Pedido</v-btn>
                                                </div>
                                                
                                                <div v-if="usuario.role != 'representante'" class="mt-3">
                                                    <v-text-field dense outlined type="number" v-model="atividade.peso" label="Peso Vendido" required step="0.01"></v-text-field>
                                                    <v-text-field dense outlined type="number"  v-model="atividade.valor" label="Valor Vendido" required step="0.01"></v-text-field>
                                                </div>
                                                <div v-if="usuario.role == 'representante'" class="mt-3">
                                                    <v-text-field dense outlined type="number" v-model="atividade.pesoXB" label="Peso KG XB" required step="0.01"></v-text-field>
                                                    <v-text-field dense outlined type="number" v-model="atividade.pesoItaperuna" label="Peso KG Itaperuna" required step="0.01"></v-text-field>
                                                </div>
                                                <br>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </b-container>
              

            <!-- DESCRIÇÃO ATIVIDADE -->
            <v-container class="mt-3">
                <v-row>
                    <v-col>
                        <b-card-title>Descrição da Atividade</b-card-title>
                        <v-card-text>Aqui você descreve as ocorrências do contato/visita
                            <v-textarea dense outlined label="Observação" placeholder="Observação da Atividade (informações importantes, Concorrentes, Preços, etc)" rows="3" v-model="atividade.observacao" required></v-textarea>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="mt-3 justify-content-center">
                <v-row v-if="this.usuario.role != 'representante'">
                    <v-col cols="12" md="6" class="mb-3">
                        <AgendaComponent :clientes="clientes" :cliente="clienteFiltradoComputed" ref="agenda" />
                    </v-col>
                </v-row>
            </v-container>
              

            
 
            <v-btn color="primary" large class="m-3" type="submit" style="bottom:20px;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">SALVAR ATIVIDADE</v-btn>
          </b-form>
      </v-card>

    <!-- MODAL DE ITENS DE PEDIDO COMEÇAM AQUI -->
    <v-dialog v-model="dialogItens" id="items">
        <ItensPedido :clienteFiltrado="clienteFiltradoComputed" :items="items" :key="Math.random()" />
    </v-dialog>
    
    <!-- MODAL DE ATIVIDADES ANTERIORES -->
    <v-dialog v-model="dialogAtividades" >
        <v-card>
            <v-card-text>
                <h4>Consultas de atividades</h4>
                <AtividadesTabela ref="atividades" :usuario="usuario" :consultasFiltradas="consultas" :key="Math.random()"/>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- MODAL DE MIX DE PRODUTOS-->
    <v-dialog v-model="dialogMix" >
        <ItensPedido  :clienteFiltrado="clienteFiltradoComputed" :items="mixProdutos" :key="Math.random()"/>
    </v-dialog>

    <!-- MODAL DETALHES CLIENTE-->
    <v-dialog v-model="dialogDetalhes">
        <v-card v-if="clienteFiltradoComputed != null">
            <v-card-text>
                <v-card-title>Cliente Filtrado</v-card-title>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6">
                            <p><strong>Código Cliente:</strong>{{clienteFiltradoComputed.codcli}}</p>
                            <p><strong>Razão Social:</strong>{{clienteFiltradoComputed.nomcli}}</p>
                            <p><strong>Nome Fantasia:</strong>{{clienteFiltradoComputed.apecli}}</p>
                            <p><strong>Telefone:</strong>{{clienteFiltradoComputed.foncli}}</p>
                            <p><strong>Email:</strong>{{clienteFiltradoComputed.intnet}}</p>
                            <p><strong>Email Documentos Eletrônicos:</strong>{{clienteFiltradoComputed.emanfe}}</p>
                            <p><strong>CNPJ:</strong>{{clienteFiltradoComputed.cnpj}}</p>
                            <p><strong>Cidade/UF:</strong>{{clienteFiltradoComputed.cidcli}}/{{clienteFiltradoComputed.sigufs}}</p>
                            <p><strong>Ramo de Atividade:</strong>{{clienteFiltradoComputed.desram}}</p>
                            <p><strong>Situação Cliente:</strong> {{clienteFiltradoComputed.sitcli == 'A' ? 'Ativo' : clienteFiltradoComputed.sitcli == 'I' ? 'Inativo' : null}}</p>

                            <p><strong>WhatsApp Marketing: </strong> {{clienteFiltradoComputed.usu_fonco3}}</p>

                            <hr>

                            <v-text-field dense outlined v-model="atividade.cliente.nomeContato" label="Nome Contato"></v-text-field>
                            <v-text-field dense outlined v-model="atividade.cliente.telContato" label="Tel. Contato"></v-text-field>
                        </v-col>

                        <v-col>
                            <v-card>
                                <v-card-text>
                                    <v-card-title>Geral</v-card-title>
                                    <v-text-field dense outlined v-model="clienteFiltradoComputed.foncli" :disabled="usuario.role == 'representante'" label="Telefone Geral"></v-text-field>
                                    <v-text-field dense outlined v-model="clienteFiltradoComputed.intnet" :disabled="usuario.role == 'representante'" label="Email Geral"></v-text-field>
                                    <v-text-field dense outlined v-model="clienteFiltradoComputed.emanfe" :disabled="usuario.role == 'representante'" label="Email Documentos Eletronicos"></v-text-field>
                                </v-card-text>
                                
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-card-title>Compras</v-card-title>
                                    <v-text-field dense outlined type="text" v-model="clienteFiltradoComputed.usu_nomcom" :disabled="usuario.role == 'representante'" label="Nome Comprador"></v-text-field>
                                    <v-text-field dense outlined type="tel" v-model="clienteFiltradoComputed.usu_fonco1" :disabled="usuario.role == 'representante'" label="Telefone Comprador 1"></v-text-field>
                                    <v-text-field dense outlined type="tel" v-model="clienteFiltradoComputed.usu_fonco2" :disabled="usuario.role == 'representante'" label="Whatsapp Comprador"></v-text-field>
                                    <v-text-field dense outlined type="email" v-model="clienteFiltradoComputed.usu_emacom" :disabled="usuario.role == 'representante'" label="Email Comprador"></v-text-field>
                                </v-card-text>
                                
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-card-title>Marketing</v-card-title>
                                    <v-text-field dense outlined type="tel" v-model="clienteFiltradoComputed.usu_fonco3" :disabled="usuario.role == 'representante'" label="Whatsapp Marketing"></v-text-field>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-card-title>Contas a Pagar</v-card-title>
                                    <v-text-field dense outlined type="text" v-model="clienteFiltradoComputed.usu_restcp" :disabled="usuario.role == 'representante'" label="Nome Responsável Contas a Pagar"></v-text-field>
                                    <v-text-field dense outlined type="tel" v-model="clienteFiltradoComputed.usu_fontc1" :disabled="usuario.role == 'representante'" label="Telefone Contas a Pagar 1"></v-text-field>
                                    <v-text-field dense outlined type="tel" v-model="clienteFiltradoComputed.usu_fontc2" :disabled="usuario.role == 'representante'" label="Telefone Contas a Pagar 2"></v-text-field>
                                    <v-text-field dense outlined type="email" v-model="clienteFiltradoComputed.usu_ematcp" :disabled="usuario.role == 'representante'" label="Email Contas a Pagar"></v-text-field>
                                </v-card-text>

                                <v-row class="text-center">
                                    <v-col>
                                        <v-btn color="primary" type="button" class="mt-1" v-if="usuario.role != 'representante'" @click="editarCliente()">Salvar Alterações</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <div class="mt-3 mb-3">
                    <v-btn color="primary" outlined type="button" class="ml-3 mt-3" @click="buscarPedidos(clienteFiltradoComputed.codcli)">Ver Últimos Pedidos</v-btn>
                    <v-btn color="primary" outlined type="button" class="ml-3 mt-3" @click="buscarMixProdutos()">Ver Mix Produtos</v-btn>
                    <v-btn color="primary" outlined type="button" class="ml-3 mt-3" @click="buscarAtividades(clienteFiltradoComputed.cnpj)">Ver Últimos Contatos</v-btn>
                </div>
            </v-card-text>
        </v-card>
            
            <!-- ULTIMOS PEDIDOS -->
            <v-card ref="ultimosPedidos">
                <v-card-text>
                    <v-row v-if="pedidos.length > 0">
                        <v-simple-table dense v-if="mostraPedidos">
                            <thead>
                                <tr>
                                    <th>Número Pedido</th>
                                    <th>Emissão</th>
                                    <th>Tipo Pedido</th>
                                    <th>Condição Pagamento</th>
                                    <th>Razao Social</th>
                                    <th>Valor Líquido</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="p in pedidos" :key="p.numped">
                                    <td>{{p.numped}}</td>
                                    <td>{{new Date(p.datemi.substring(0,4)+'/'+p.datemi.substring(5,7)+'/'+p.datemi.substring(8,10)).toLocaleDateString('pt-BR')}}</td>
                                    <td>{{p.tnspro == 90100? 'Venda' : p.tnspro == 90110 ? 'Bonificação' : p.tnspro == 90111 ? 'Troca' : 'Amostra'}}</td>
                                    <td>{{p.descpg}}</td>
                                    <td>{{p.nomcli}}</td>
                                    <td>{{(p.vlrori).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}}</td>
                                    <td><v-btn type="button" color="primary" small outlined @click="buscarItems(p.numped)">Ver Items</v-btn></td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-row>
                </v-card-text>
            </v-card>


    </v-dialog>

  </div>
</template>

<script>
import AtividadesTabela from './Atividades_Tabela';
import ItensPedido from './Itens_Pedido';
import FiltroClientes from '@/components/FiltroClientes'
import AgendaComponent from '@/components/Agenda_Component'
export default {
    components:{
        AtividadesTabela,
        ItensPedido,
        FiltroClientes,
        AgendaComponent
    },

    mounted(){
        this.token = this.tkn;
        let t = sessionStorage.getItem('token');  
        this.token = t;
        let dec = JSON.parse(atob(t.split('.')[1]));
        this.usuario = dec;
        this.buscarClientes();
    },

    data(){
        return{
            tab:null,
            dialogDetalhes:false,
            dialogItens:false,
            dialogAtividades:false,
            dialogMix:false,
            atividade:{
                cliente:{},
                tipo:'',
                data:`${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2,'0')}-${String(new Date().getDate()).padStart(2,'0')}`,
                observacao:'',
                finalizada:true,
                canal:'',
                peso:null,
                valor:null,
                pedido:'',
                pesoXB:null,
                pesoItaperuna:null
            },
            
            edicaoCliente:{},
            cliente:{},
            clienteBolsao:null,
            clientesRazao:[],
            clientes:[],
            clienteFiltradoProp:{},

            pedidos:[],
            items:[],
            mixProdutos:[],
            consultas:[],
            token:'',
            protocolo:null,
            mostraCliente:true,
            mostraPedidos:true,
            usuario:{},
            loading:false,
        }
    },
    props:{
        tkn:String
    },
    methods:{

        obterToken(){
            // this.token = sessionStorage.getItem('token')
        },

        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        buscarClientes(){
            this.$axios.get('/clientes',{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp => {
                this.clientes = resp.data.clientes;
            })
            .catch(err =>{
                if(err.response.status == 401){
                    sessionStorage.clear();
                    this.$router.push('/');
                    alert('Sessão Expirada');
                }else{
                    alert('Cliente não disponível para sua representação')
                    console.log(err.response);
                }
            })
        },

        buscarClienteBolsao(cnpj){
            this.$axios.get('/clientes/bolsao/'+cnpj,{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp => {
                this.clienteBolsao = resp.data;
                console.log('Cliente de Bolsão Identificado')
            })
            .catch(err =>{
                if(err.response.status == 401){
                    sessionStorage.clear();
                    this.$router.push('/');
                    alert('Sessão Expirada');
                }else if(err.response.status == 403){
                    console.log(err.response);
                    if(!this.$route.query.cnpj){
                        alert('Operação não autorizada');
                    }

                }
            })
        },

        editarCliente(){
            let clienteFiltrado = this.clienteFiltradoComputed;
            this.$axios.post('/clientes/alterar',{cliente:clienteFiltrado},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(() => {
                alert('Dados Atualizados com sucesso!')
            }).catch(err =>{
                alert('ERRO',err)
            })
        },

        escolheCliente(cnpj){
            this.$set(this.cliente,'cnpj',cnpj);
        },
        
        insereCliente(cliente){
            this.atividade.cliente = cliente;
            this.pedidos = [];
            // this.buscarAtividades(cliente.cnpj);
        },

        removeCliente(){
            this.atividade.cliente = {};
            this.clienteBolsao = null;
            this.pedidos = [];
        },

        ver(){
            alert(this.atividade.cliente.nomcli);
        },

        limpaPeso(){
            if(!this.atividade.tipo.includes('Venda')){
                this.atividade.peso = null;
            }
        },

        buscarPedidos(codcli){
            this.$axios.get('/pedidos/'+codcli,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp =>{
                console.log(resp.data);
                if(resp.data.pedidos){
                    this.pedidos = resp.data.pedidos;
                    // let elemento = this.$refs.ultimosPedidos;
                    // let top = elemento.offsetTop;
                    // window.scrollTo(0, top);
                    // elemento.scrollIntoView({ behavior: 'smooth' });
                }else{
                    alert('Nenhum pedido recente encontrado')
                }
            })
            .catch(err =>{
                alert('Nenhum pedido recente encontrado ERRO')
                console.log(err);
            })

            // this.pedidos.forEach(p =>{
            //     this.$axios.get('/pedidos/items/'+p.numped,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            //     .then(resp =>{
            //         this.items.push(resp.data);
            //     })
            //     .catch(err =>{
            //         console.log(err);
            //     })
            // })
        },

        pesoValorPedido(){
            if(this.atividade.pedido != ""){
                this.$axios.get(`/pedidos/items/${this.atividade.pedido}?codcli=${this.atividade.cliente.codcli}`,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
                .then(resp =>{
                    let items = resp.data;
                    if(items){
                        let valor = 0;
                        let peso = 0;
                        let pesoXB = 0;
                        let pesoItaperuna = 0;
                        items.forEach(i => {
                            valor += i.qtdped * i.preuni;
                            peso += i.qtdped * i.pesliq;

                            if(i.nommar == 'XAMEGO BOM'){
                                pesoXB += i.qtdped * i.pesliq;
                            }
                            if(i.nommar == 'ITAPERUNA'){
                                pesoItaperuna += i.qtdped * i.pesliq;
                            }
                            
                        });
                        
                        this.atividade.peso = peso;
                        this.atividade.valor = valor;
                        this.atividade.pesoXB = pesoXB;
                        this.atividade.pesoItaperuna = pesoItaperuna;
                        
                    }
                })
                .catch(err =>{
                    if(err.response.data.mensagem){
                        alert('Atenção: \n' + err.response.data.mensagem);
                    }else{
                        alert('Nenhum Pedido encontrado');
                    }
                    this.atividade.peso = 0;
                    this.atividade.valor = 0;
                    this.atividade.pesoXB = 0;
                    this.atividade.pesoItaperuna = 0;
                })
            }else{
                alert('É necessário um número de pedido')
            }
        },

        buscarMixProdutos(){

            this.$axios.get('/pedidos/mix/'+this.clienteFiltradoComputed.codcli,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp =>{
                this.mixProdutos = resp.data;
                // this.$bvModal.show('mix');
                this.dialogMix = true;
            })
            .catch(err =>{
                console.log(err);
            })



            
        },

        buscarItems(numped){
            this.$axios.get(`/pedidos/items/${numped}?codcli=${this.atividade.cliente.codcli}`,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp =>{
                console.log(resp.data);
                this.items = resp.data;
                const pedidoOri = this.pedidos.find(p => p.numped == numped);
                if(pedidoOri){
                    this.clienteFiltradoProp = this.clienteFiltradoComputed;
                    this.clienteFiltradoProp.codrep = pedidoOri.codrep;
                    this.clienteFiltradoProp.nomrep = pedidoOri.nomrep;
                    // this.clienteFiltrado.descpg = pedidoOri.descpg;
                    // this.clienteFiltrado.numped = numped;
                    // this.clienteFiltrado.tnspro = pedidoOri.tnspro;
                    // this.clienteFiltrado.ciffob = pedidoOri.ciffob;
                    // this.clienteFiltrado.cgccpf = this.atividade.cliente.cnpj;

                }
            }).then(() => {
                this.dialogItens = true;
            })
            .catch(err =>{
                console.log(err);
            })
        },

        salvarAtividade(evt){
            evt.preventDefault();

            if(this.usuario.role != 'representante'){
                this.$refs.agenda.criarAgendamento();
            }

            if(!this.atividade.peso){
                if(this.atividade.pesoXB >= 0 || this.atividade.pesoItaperuna >=0){
                    this.atividade.peso = (this.atividade.pesoXB*1) + (this.atividade.pesoItaperuna*1)
                }
            }
            if(!this.atividade.cliente.cnpj){
                alert('É necessário assossiar pelo menos um cliente COM CNPJ antes de salvar uma atividade');
            }else{
                this.atividade.protocolo = this.protocolo;
                this.$axios.post('/atividades/incluir',{atividade:this.atividade},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
                .then(resp =>{
                    alert('Atividade Postada com Sucesso');
                    console.log(resp);
                    this.$router.push('/');
                })
                .catch(err =>{
                    alert('Erro ao postar Atividade');
                    console.log(err);
                })
            }
        },

        buscarAtividades(cnpj){
            this.$axios.get('/atividades/cliente/'+cnpj,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp =>{
                if(resp.data.length > 0){
  
                    this.consultas = resp.data;
                    this.atividade.cliente.nomeContato = this.consultas[0].cliente.nomeContato;
                    this.atividade.cliente.telContato = this.consultas[0].cliente.telContato;

                    this.dialogAtividades = true;
                }else{
                    this.consultas = [];
                }
            })
            .catch(err =>{
                if(err.response.data.status == 401){
                    alert('Sessão expirada');
                    this.$router.push('/login');
                }
                else{
                    alert('Erro ao buscar atividades')
                }
            })
        },

        importarProtocolo(){
            if(this.protocolo != null){
                this.$axios.get('/atividades/exportar/'+this.protocolo,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
                .then(resp =>{
                    if(resp.data.length > 0){

                        this.insereCliente(resp.data[0]);
                    }else{
                        alert('Protocolo não encontrado')
                    }
                })
                .catch(err =>{
                    alert(err.response.data);
                })
            }else{
                alert('É necessário digitar um número de protocolo primeiro')
            }
        },

        alterarAtividade(id){
            this.$axios.post('/atividades/alterar',{id:id},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(() =>{
                alert('Situação Alterada com sucesso!');
            }).catch(err =>{
                console.log(err);
                alert('Erro ao alterar');
            })
        },

        converteDate(data){
            let d = new Date(data);
            d.setDate(d.getDate()+1);
            return d;
        },

    },
    computed:{
        clienteFiltradoComputed(){
            if(this.clientes){
                let clientes = this.clientes;

                //CONFERE SE FOI DIGITADO O CNPJ
                if(this.cliente.cnpj){
                    if(this.cliente.cnpj != ''){
                        clientes = this.clientes.find(c => c.cnpj.startsWith(this.cliente.cnpj));
                        if(clientes){
                            this.insereCliente(clientes);
                            return clientes;
                        }
                        //SE NÃO ENCONTRAR NA LISTA DE CLIENTES BAIXADOS ANTES
                        else{
                            if(this.clienteBolsao){
                                this.insereCliente(this.clienteBolsao);
                                return this.clienteBolsao;
                            }else{
                                this.buscarClienteBolsao(this.cliente.cnpj);
                                this.removeCliente();
                                return null;
                            }

                            
                        }
                    }else{
                        this.removeCliente();
                        return null;
                    }
                }else if(this.cliente.codcli){
                    if(this.cliente.codcli != ''){
                        clientes = this.clientes.find(c => c.codcli == this.cliente.codcli);
                        if(clientes){
                            this.insereCliente(clientes);
                            return clientes;
                        }else{
                            this.removeCliente();
                            return null;
                        }
                    }else{
                        this.removeCliente();
                        return null;
                    }
                }
                else{
                    this.removeCliente();
                    return null;
                }
            }
            else{
                this.removeCliente();
                return null;
            }
        },

        hoje(){
            let data = new Date();
            let strData = `${data.getFullYear()}-${String(data.getMonth()+1).padStart(2,'0')}-${String(data.getDate()).padStart(2,'0')}`
            return strData;
        },

        ontem(){
            let data = new Date();
            let strData = `${data.getFullYear()}-${String(data.getMonth()+1).padStart(2,'0')}-${String(data.getDate()-1).padStart(2,'0')}`
            return strData;
        },
        
        saudacao(){
            let dec = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
            return `Olá ${dec.representacao}`
        }
    }
}
</script>

<style>
@media screen and (max-width:500px) {
    #logoimg{
        width:80%!important;
    }

}
</style>