var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-text',{attrs:{"text-center":""}},[_c('v-card-title',[_vm._v("Top 20 Cidades Brasil (Últimos 12 meses)")]),_c('v-data-table',{attrs:{"items":_vm.topCidades.top_cidades,"headers":[      
                        {text:'Cidade',value:'cidcli'},                          
                        {text:'Peso Líquido',value:'pesliq',align:'right'},
                        {text:'%',value:'rep',align:'right'}
                    ]},scopedSlots:_vm._u([{key:`item.cidcli`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.cidcli)+" ( "+_vm._s(item.sigufs)+" ) ")])]}},{key:`item.pesliq`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(Number(item.Peso - item.devolucao).toLocaleString('pt-br',{maximumFractionDigits: 2, minimumFractionDigits: 2}))+" ")])]}},{key:`item.rep`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(Number((Number(item.Peso - item.devolucao) / Number(_vm.topCidades.top_cidades.reduce((a,b) => a + (b.Peso - b.devolucao), 0)))*100).toLocaleString('pt-br',{maximumFractionDigits: 2, minimumFractionDigits: 2}))+"% ")])]}}],null,true)})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-2 mb-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex m-1",attrs:{"height":"100%"}},[_c('v-card-text',{staticClass:"text-center"},[(_vm.regiaoEscolhida != null)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){_vm.regiaoEscolhida = null}}},[_vm._v("limpar filtro")]):_vm._e(),_c('v-card-title',[_vm._v("Faturado por Região (KG) - Últimos 12 meses")]),_c('p',[_c('strong',[_vm._v("Clique em alguma região para filtrar as UFS")])]),_c('v-data-table',{attrs:{"dense":"","sort-by-desc":['rep'],"items":_vm.regioesComputed,"headers":[
                            {text:'Regiao',value:'regiao'},
                            {text:'Peso Líquido',value:'pesliq',align:'right'},
                            {text:'%',value:'rep',align:'right'},
                            {text:'Detalhar',value:'det'}]},on:{"click:row":_vm.escolheRegiao},scopedSlots:_vm._u([{key:`item.det`,fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.comparativo(item)}}},[_vm._v("comparar "),_c('v-icon',{staticClass:"m-1",attrs:{"dense":""}},[_vm._v("mdi-chart-timeline-variant")])],1)]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex m-1",attrs:{"height":"100%"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-card-title',[_vm._v("Faturado por UF (KG) - Últimos 12 meses")]),_c('p',[_c('strong',[_vm._v("Clique em alguma UF para mostrar as cidades")])]),_c('v-data-table',{attrs:{"dense":"","sort-by-desc":['rep'],"items":_vm.UFSComputed,"headers":[
                            {text:'UF',value:'sigufs'},
                            {text:'Peso Líquido',value:'pesliq',align:'right'},
                            {text:'%',value:'rep',align:'right'},
                            {text:'Detalhar',value:'det'}]},on:{"click:row":_vm.escolheUF},scopedSlots:_vm._u([{key:`item.det`,fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.comparativo(item)}}},[_vm._v("comparar "),_c('v-icon',{staticClass:"m-1",attrs:{"dense":""}},[_vm._v("mdi-chart-timeline-variant")])],1)]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex m-1",attrs:{"height":"100%"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-card-title',[_vm._v("Faturado por Cidade (KG) - Últimos 12 meses")]),(_vm.UFEscolhida != null)?_c('h5',[_vm._v("UF - "+_vm._s(_vm.UFEscolhida))]):_vm._e(),_c('v-data-table',{attrs:{"dense":"","sort-by-desc":['rep'],"loading":_vm.loadingCidades,"items":_vm.faturadoCidades.cidades,"headers":[
                            {text:'Cidade',value:'cidcli'},
                            {text:'Peso Líquido',value:'pesliq',align:'right'},
                            {text:'%',value:'rep',align:'right'},
                            {text:'Detalhar',value:'det'}]},scopedSlots:_vm._u([{key:`item.det`,fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.comparativo(item)}}},[_vm._v("comparar "),_c('v-icon',{staticClass:"m-1",attrs:{"dense":""}},[_vm._v("mdi-chart-timeline-variant")])],1)]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialogComparativo),callback:function ($$v) {_vm.dialogComparativo=$$v},expression:"dialogComparativo"}},[_c('v-card',[_c('v-btn',{staticClass:"m-1",attrs:{"fab":"","x-small":"","color":"secondary","dark":""},on:{"click":function($event){_vm.dialogComparativo = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-card-title',[_vm._v("Comparativo Faturado (KG) - Por "+_vm._s(_vm.cidadeEscolhida ? 'Cidade' : _vm.UFEscolhida ? 'UF' : 'Região')+" - "+_vm._s(_vm.cidadeEscolhida ? _vm.cidadeEscolhida : _vm.UFEscolhida ? _vm.UFEscolhida : _vm.regiaoEscolhida)+" - "+_vm._s(_vm.acumulado?'Acumulado':'Mês a Mês')+" ")]),_c('small',[_vm._v(_vm._s(_vm.cidadeEscolhida ? _vm.cidadeEscolhida : _vm.UFEscolhida ? _vm.UFEscolhida : _vm.regioes[_vm.regiaoEscolhida]))]),_c('v-divider'),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.acumulado=!_vm.acumulado}}},[_vm._v("Visão: "+_vm._s(_vm.acumulado?'Mês a Mês':'Acumulado')+" "),_c('v-icon',{staticClass:"m-1",attrs:{"dense":""}},[_vm._v(_vm._s(_vm.acumulado ? 'mdi-chart-bell-curve' : 'mdi-chart-bell-curve-cumulative'))])],1),_c('v-card',{attrs:{"loading":_vm.loadingHistoricos}},[(_vm.historicoRegioes.length == 0)?_c('v-card-title',[_vm._v("Carregando informações")]):_vm._e(),(_vm.historicoRegioes.length > 0)?_c('LineChartX',{attrs:{"chart-data":_vm.graphDataComputed,"chart-options":_vm.chartOptions}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }