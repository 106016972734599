<template>
    <v-card class="text-left printSection">
      
      <v-card-title>Detalhes Pedido - {{detalhesPedido.destns}}</v-card-title>
      <b-row class="justify-content-center">
        <b-img src="@/assets/logo_xb.png" style="max-width:150px;"></b-img>
      </b-row>
      
      <b-row class="text-center">
        <b-col>
          <v-btn color="primary" @click="enviarImpressao()">Imprimir Pedido<v-icon>mdi-printer</v-icon></v-btn>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-3">
        <b-col cols="10" class="text-center">
          <h3><strong>Pedido de {{
                clienteFiltrado.tnspro == '90100' ? 'Venda' : 
                clienteFiltrado.tnspro == '90110' ? 'Bonificação' : 
                clienteFiltrado.tnspro == '90111' ? 'Troca':
                clienteFiltrado.tnspro == '90112' ? 'Amostra': clienteFiltrado.tnspro}}</strong></h3>
          <h5 v-if="clienteFiltrado.pedrep">Emissão: {{clienteFiltrado.datemi}} - Ordem de Compra: {{clienteFiltrado.pedrep}}</h5>
          <h5 v-if="!clienteFiltrado.pedrep">Emissão: {{clienteFiltrado.datemi}}</h5>
        </b-col>
      </b-row>

      <v-card class="m-1">
        <v-card-text>
          <v-row dense>
            <v-col cols="1"><v-text-field dense v-model="clienteFiltradoData.numped" label="Número Pedido Xamego" readonly></v-text-field></v-col>
            <v-col cols="1"><v-text-field dense v-model="clienteFiltradoData.codcli" label="Código Cliente" readonly></v-text-field></v-col>
            <v-col cols="3"><v-text-field dense v-model="clienteFiltradoData.nomcli" label="Razão Social" readonly></v-text-field></v-col>
            <v-col cols="4"><v-text-field dense v-model="clienteFiltradoData.apecli" label="Nome Fantasia" readonly></v-text-field></v-col>
            <v-col cols="3"><v-text-field dense v-model="clienteFiltradoData.cgccpf" label="CNPJ" readonly></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-text-field dense v-model="clienteFiltradoData.emanfe" label="Email" readonly></v-text-field></v-col>
            <v-col cols="3"><v-text-field dense v-model="clienteFiltradoData.foncli" label="Telefone" readonly></v-text-field></v-col>
            <v-col cols="3"><v-text-field dense v-model="clienteFiltradoData.foncl2" label="Telefone 2" readonly></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-text-field dense v-model="clienteFiltradoData.cidcli" label="Cidade" readonly></v-text-field></v-col>
            <v-col cols="1"><v-text-field dense v-model="clienteFiltradoData.sigufs" label="UF" readonly></v-text-field></v-col>
            <v-col cols="5"><v-text-field dense v-model="clienteFiltradoData.baicli" label="Bairro" readonly></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="8"><v-text-field dense v-model="clienteFiltradoData.endcli" label="Endereço" readonly></v-text-field></v-col>
            <v-col cols="2"><v-text-field dense v-model="clienteFiltradoData.nencli" label="Número" readonly></v-text-field></v-col>
            <v-col cols="2"><v-text-field dense v-model="clienteFiltradoData.cepcli" label="CEP" readonly></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-text-field dense v-model="clienteFiltradoData.nomrep" label="Representante" readonly></v-text-field></v-col>
            <v-col cols="6" v-if="itemsData.length > 0" >
              <v-text-field dense v-model="itemsData[0].destpr" label="Tabela de Preço" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-text-field dense v-model="clienteFiltradoData.descpg" label="Condição de Pagamento" readonly></v-text-field></v-col>
            <v-col cols="6"><v-text-field dense v-model="clienteFiltradoData.ciffob" label="Frete" readonly></v-text-field></v-col>
          </v-row>
          <v-row v-if="clienteFiltradoData.obsmot">
            <v-col>
                <p><strong>Observação Cliente: </strong> {{ clienteFiltradoData.obsmot }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><v-textarea v-model="clienteFiltradoData.obsped" label="Observação Pedido" readonly></v-textarea></v-col>
            <v-col cols="6"><v-textarea v-model="clienteFiltradoData.obsnf" label="Observação Nota Fiscal" readonly></v-textarea></v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="m-1">
          <v-simple-table dense v-if="items">
            <thead>
              <th class="text-center">Cód. Produto</th>
              <th class="text-center">Descrição</th>
              <th class="text-center">Qtd</th>
              <th class="text-center">Valor Uni Líq.</th>
              <th class="text-center">Valor Total Líq.</th>
              <th class="text-center">Peso Líq.</th>
              <th class="text-center" v-show="items[0].pesbru">Peso Bruto</th>
              <th class="text-center">% Desc. Avista</th>
              <th class="text-center">% Desc. Campanha</th>
              <th class="text-center">% Desc. Extra</th>
              <th class="text-center">Valor Tabela</th>
            </thead>
            <tbody>
              <tr v-for="produto in items" :key="produto.codpro" style="page-break-inside:auto;">
                <td>{{produto.codpro}}</td>
                <td>{{produto.despro}}</td>
                <td class="text-right">{{produto.qtdped}}</td>
                <td class="text-right">{{Number.parseFloat(produto.preuni).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                <td class="text-right">{{Number.parseFloat(produto.preuni * produto.qtdped).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                <td class="text-right">{{Number.parseFloat(produto.pesliq * produto.qtdped).toFixed(2).toLocaleString('pt-br')}}</td>
                <td class="text-right" v-show="produto.pesbru">{{Number.parseFloat(produto.pesbru * produto.qtdped).toFixed(2).toLocaleString('pt-br')}}</td>
                <td class="text-center">{{(produto.desavi ? produto.desavi : 0)}}%</td>
                <td class="text-center">{{(produto.descam ? produto.descam : 0)}}%</td>
                <td class="text-center">{{(produto.desext ? produto.desext : 0)}}%</td>
                <td>{{Number.parseFloat(produto.preuni).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
              </tr>
              <tr>
                <th>Totais</th>
                <td></td>
                <th class="text-right">{{totalQtd}}</th>
                <th></th>
                <th class="text-right">{{Number.parseFloat(totalVlrliq).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</th>
                <th class="text-right">{{Number.parseFloat(totalPesliq).toFixed(2).toLocaleString('pt-br')}}</th>
                <th class="text-right">{{Number.parseFloat(totalPesbru).toFixed(2).toLocaleString('pt-br')}}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tbody>
          </v-simple-table>
      
      </v-card>

    </v-card>
</template>

<script>
export default {
    props:{
        clienteFiltrado:Object,
        items: Array,
        numped: Number
    },
    data(){
        return{
            clienteFiltradoData:{},
            itemsData:[],
            detalhesPedido:{},
        }
    },
    computed:{
      totalQtd(){
        let totalQtd = 0;
        for (const i of this.items) {
          totalQtd += i.qtdped;
        }
        return totalQtd;
      },
      totalVlrliq(){
        let vlrLiq = 0;
        for (const i of this.items) {
          vlrLiq += (i.preuni * i.qtdped);
        }
        return vlrLiq;
      },
      totalPesliq(){
        let pesLiq = 0;
        for (const i of this.items) {
          pesLiq += (i.pesliq * i.qtdped);
        }
        return pesLiq;
      },
      totalPesbru(){
        let pesBru = 0;
        for (const i of this.items) {
          pesBru += (i.pesbru * i.qtdped);
        }
        return pesBru;
      },
    },
    mounted(){
      this.clienteFiltradoData = this.clienteFiltrado;
      this.itemsData = this.items.map(i => {
        i.destpr = `${i.codtpr} - ${i.destpr}`;
        return i;
      });
    },
    methods:{

      enviarImpressao(){
        let detalhesPedido = this.clienteFiltrado;
        detalhesPedido.itensProduto = this.items;
        detalhesPedido.destns = this.clienteFiltrado.tnspro == '90100' ? 'Venda' : 
                                this.clienteFiltrado.tnspro == '90110' ? 'Bonificação' : 
                                this.clienteFiltrado.tnspro == '90111' ? 'Troca':
                                this.clienteFiltrado.tnspro == '90112' ? 'Amostra': this.clienteFiltrado.tnspro
        detalhesPedido.ciffob = this.clienteFiltrado.ciffob == 'C' || this.clienteFiltrado.ciffob == 'CIF' ? 'CIF' : 'FOB';
        detalhesPedido.itensProduto[0].descpg = this.clienteFiltrado.descpg;
        detalhesPedido.itensProduto.map(p => {
          p.vlrliq = p.preuni;
          p.vlrbru = p.preuni;
          return p;
        })

        sessionStorage.setItem('pedido',JSON.stringify(detalhesPedido));
        sessionStorage.setItem('rota',this.$route.fullPath);
        this.$router.push('/pre-pedidos/imprimir');
      },
    }
}
</script>

<style scoped>
  @media print{

    @page{
      margin: 0.5cm;
    }
    body {
      font: 12pt Georgia, "Times New Roman", Times, serif;
      line-height: 1.3;
    }
    .print {
      display: block;
    }
  }
</style>