<template>
    <div>

        <v-row dense>
            <!-- TOTAL FATURADO NO MES -->
            <v-col>
            <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                <v-card-text class="text-center">
                <div v-if="faturado.faturado.length > 0">
                    <v-progress-circular
                    :rotate="-90"
                    :size="125"
                    :width="15"
                    :value="previsto === undefined ? 0 : Number.parseFloat((faturado.total/previsto.pesliq)*100)"
                    color="teal"
                    >
                    {{ Number.parseFloat(faturado.total).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) }}
                    <br>
                    {{previsto === undefined ? 0 : Number.parseFloat((faturado.total/previsto.pesliq)*100).toFixed(2)}}%
                    </v-progress-circular>
                </div>
                <h4>Faturamento Geral do Mês (KG)</h4>
                <div class="text-center">
                    <!-- <small>Não inclui amostras,trocas e bonificações</small> -->
                </div>
                <v-divider></v-divider>
                <p><strong>Peso (KG):</strong> {{faturado.faturado.length > 0 ? Number.parseFloat(faturado.faturado[0].Peso).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) : 0}}
                | <strong>Devolvido (KG):</strong> {{faturado.devolvido.length > 0 ?  Number.parseFloat(faturado.devolvido[0].xpliq).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}): 0}}</p>
                </v-card-text>
            </v-card>

            </v-col>
            <v-col cols="12" sm="8">
                        
                <!-- MES A MES -->
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                    <v-card-text class="text-center" >
                        <h4>{{`Histórico Mês a Mês ${new Date().getFullYear()-1} x ${new Date().getFullYear()}`}} (KG)</h4>
                        <v-divider></v-divider>
                        <!-- <small>Abate Devoluções e NÃO inclui trocas amostras e bonificações</small> -->
                        <LineChartX v-if="faturado.historico.length > 0" :chart-data="chartHistory" :chart-options="chartOptions"/>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>

        <v-row dense>
              <v-col cols="12" sm="4">

                <!-- FATURADO 12 MESES -->
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                  <v-card-text class="text-center">
                    <h2>{{faturado.anual.length > 0 ? Number.parseFloat(faturado.anual[0].Peso - faturado.anualdevolvido[0].xpliq).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) : 0}}</h2>
                    <h4>Total Faturado Últimos 12 Meses(KG)</h4>
                    <v-divider></v-divider>  
                    <!-- <small> Não inclui trocas, amostras e bonificações. Não inclui mês atual. </small> -->
                  </v-card-text>
                </v-card>

              </v-col>
            <v-col cols="12" sm="8">

                <!-- MES A MES ACUMULADO -->
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                    <v-card-text class="text-center" >
                        <h4>{{`Histórico Mês a Mês ${new Date().getFullYear()-1} x ${new Date().getFullYear()}`}} Acumulado (KG)</h4>
                        <!-- <small>Abate Devoluções e NÃO inclui trocas amostras e bonificações</small> -->
                        <v-divider></v-divider>
                        <LineChartX v-if="faturado.historico.length > 0" :chart-data="chartHistoryAcumulado" :chart-options="chartOptions"/>
                    </v-card-text>
                </v-card>

            </v-col>

        </v-row>

        <!-- <v-row dense>
            <v-col>
                <Customizados :token="token" />
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import { Line as LineChartX } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale,LineElement, PointElement,ArcElement,Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale,PointElement,ArcElement,Filler);
// import Customizados from '@/components/relatorios/Customizados'
export default {
    components:{
        LineChartX,
        // Customizados,
    },
    props:{
        token:String
    },
    mounted(){
        this.$nextTick(() => {
            this.obterPrevisto();
            this.obterFaturado();
        })
    },
    data(){
        return{
            meses:['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'],
            chartHistory:{},
            chartHistoryAcumulado:{},
            chartOptions:{
                responsive: true, 
                maintainAspectRatio: false,
                showAllTooltips: true,
                legend:{
                    display:true,
                    position:'bottom'
                },
            },
            faturado: {
                faturado: [],
                devolvido: [],
                maioresClientes: [],
                maioresGrupos: [],
                anual: [],
                anualdevolvido: [],
                historico: [],
            },
            loadingCards: false,
            previsto: {},
        }
    },
    methods:{
        obterFaturado(){
            this.loadingCards = true;
            this.$axios.get('/relatorios/faturado?soVenda=false',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp =>{
                let anoAtual = new Date().getFullYear();
                let anoAnterior = anoAtual -1;

                this.faturado = resp.data;
                console.dir(this.faturado);
                //GRAFICO HISTORICO ANUAL
                let dadoh1 = this.faturado.historico.filter(h => h.ano == anoAtual);
                let devolvidoh1 = this.faturado.historicoDevolucao.filter(h => h.ano == anoAtual);

                //GRAFICO HISTORICO Ano Passado
                let dadoh2 = this.faturado.historico.filter(h => h.ano == anoAnterior);
                let devolvidoh2 = this.faturado.historicoDevolucao.filter(h => h.ano == anoAnterior);

                //mes a mes
                let dataset1 = [];
                let dataset2 = [];
                //acumulado
                let dataset3 = [];
                let dataset4 = [];

                let accbefore = 0;
                let accafter = 0;
                if(dadoh1.length > 0  || dadoh2.length > 0){
                    for (let i = 1; i <= 12; i++) {
                        
                        let m = dadoh1.filter(d => d.mes == i);
                        let dev = devolvidoh1.filter(d => d.mes == i);

                        if(m.length > 0){
                            let calc = m.reduce((a,x) => a + x.pesliq,0) - dev.reduce((a,x) => a + x.devolvido,0);

                            dataset1.push(calc);
                            accafter += calc;
                        }else{
                            dataset1.push(0);
                        }
                        dataset3.push(accafter);
            
                        let k = dadoh2.filter(d => d.mes == i);
                        let devk = devolvidoh2.filter(d => d.mes == i);
                        if(k.length > 0){
                            let calc = k.reduce((a,x) => a + x.pesliq,0) - devk.reduce((a,x) => a + x.devolvido,0);
                            dataset2.push(calc);
                            accbefore += calc;
                        }else{
                            dataset2.push(0);
                        }
                        dataset4.push(accbefore);
                    }
                
                }

                this.chartHistory.labels = this.meses;
                this.chartHistory.datasets = [
                    {
                        backgroundColor: "rgba(1, 116, 188, 0.50)",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        label: new Date().getFullYear(),
                        data: dataset1,
                        fill: true,
                        tension: 0.3
                    },
                    {
                        backgroundColor: "rgba(188, 116, 1, 0.50)",
                        borderColor: "rgba(188, 116, 1, 0.50)",
                        label: new Date().getFullYear()-1,
                        data: dataset2,
                        fill: true,
                        tension: 0.3
                    }
                ]
                this.chartHistoryAcumulado.labels = this.meses;
                this.chartHistoryAcumulado.datasets = [
                    {
                        backgroundColor: "rgba(1, 116, 188, 0.50)",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        label: new Date().getFullYear(),
                        data: dataset3,
                        fill: true,
                        tension: 0.3
                    },
                    {
                        backgroundColor: "rgba(188, 116, 1, 0.50)",
                        borderColor: "rgba(188, 116, 1, 0.50)",
                        label: new Date().getFullYear()-1,
                        data: dataset4,
                        fill: true,
                        tension: 0.3
                    }
                ]

                this.loadingCards = false;
            })
            .catch(err =>{
                console.log(err);
                alert('Não foi possível buscar faturado');
            })
        },
        obterPrevisto(){
            this.$axios.get('/relatorios/previsto',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp =>{
                this.previsto = resp.data[0];
                this.busy = false;
            })
            .catch(err =>{
                console.log(err);
                alert('Não foi possível buscar previsto');
            })
        },
    }
}
</script>

<style>

</style>