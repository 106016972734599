<template>
    <div> 
        <v-row>
            <v-col cols="6">
                <v-card>
                    <v-card-text text-center>
                        <v-card-title>Top 20 Cidades Brasil (Últimos 12 meses)</v-card-title>
                        <v-data-table :items="topCidades.top_cidades" :headers="[      
                            {text:'Cidade',value:'cidcli'},                          
                            {text:'Peso Líquido',value:'pesliq',align:'right'},
                            {text:'%',value:'rep',align:'right'}
                        ]">
                            <template v-slot:[`item.cidcli`]="{ item }">
                                <span>
                                    {{ item.cidcli }} ( {{ item.sigufs }} )
                                </span>
                            </template>
                            <template v-slot:[`item.pesliq`]="{ item }">
                                <span>
                                    {{ Number(item.Peso - item.devolucao).toLocaleString('pt-br',{maximumFractionDigits: 2, minimumFractionDigits: 2}) }}
                                </span>
                            </template>
                            <template v-slot:[`item.rep`]="{ item }">
                                <span>
                                    {{ Number((Number(item.Peso - item.devolucao) / Number(topCidades.top_cidades.reduce((a,b) => a + (b.Peso - b.devolucao), 0)))*100).toLocaleString('pt-br',{maximumFractionDigits: 2, minimumFractionDigits: 2}) }}%
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-2 mb-2" dense>
            <v-col cols="12" md="4">
                <v-card height="100%" class="d-flex m-1">
                    <v-card-text class="text-center">
                        <v-btn x-small color="primary" @click="regiaoEscolhida = null" v-if="regiaoEscolhida != null">limpar filtro</v-btn>
                        <v-card-title>Faturado por Região (KG) - Últimos 12 meses</v-card-title>
                        <!-- <small>Não inclui trocas, amostras e bonificações. Não inclui mês atual</small> -->
                        <p><strong>Clique em alguma região para filtrar as UFS</strong></p>
                        <v-data-table
                            dense
                            :sort-by-desc="['rep']"
                            :items="regioesComputed"
                            :headers="[
                                {text:'Regiao',value:'regiao'},
                                {text:'Peso Líquido',value:'pesliq',align:'right'},
                                {text:'%',value:'rep',align:'right'},
                                {text:'Detalhar',value:'det'}]"
                            @click:row="escolheRegiao">
                            <template v-slot:[`item.det`]="{ item }">
                                <v-btn x-small color="primary" @click="comparativo(item)">comparar <v-icon dense class="m-1">mdi-chart-timeline-variant</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card height="100%" class="d-flex m-1">
                    <v-card-text class="text-center">
                        <v-card-title>Faturado por UF (KG) - Últimos 12 meses</v-card-title>
                        <!-- <small>Não inclui trocas, amostras e bonificações. Não inclui mês atual</small> -->
                        <p><strong>Clique em alguma UF para mostrar as cidades</strong></p>
                        <v-data-table
                            dense
                            :sort-by-desc="['rep']"
                            :items="UFSComputed"
                            :headers="[
                                {text:'UF',value:'sigufs'},
                                {text:'Peso Líquido',value:'pesliq',align:'right'},
                                {text:'%',value:'rep',align:'right'},
                                {text:'Detalhar',value:'det'}]"
                            @click:row="escolheUF">
                            <template v-slot:[`item.det`]="{ item }">
                                <v-btn x-small color="primary" @click="comparativo(item)">comparar <v-icon dense class="m-1">mdi-chart-timeline-variant</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card height="100%" class="d-flex m-1">
                    <v-card-text class="text-center">
                        <v-card-title>Faturado por Cidade (KG) - Últimos 12 meses</v-card-title>
                        <h5 v-if="UFEscolhida != null">UF - {{UFEscolhida}}</h5>
                        <!-- <small>Não inclui trocas, amostras e bonificações. Não inclui mês atual</small> -->
                        <v-data-table
                            dense
                            :sort-by-desc="['rep']"
                            :loading="loadingCidades"
                            :items="faturadoCidades.cidades"
                            :headers="[
                                {text:'Cidade',value:'cidcli'},
                                {text:'Peso Líquido',value:'pesliq',align:'right'},
                                {text:'%',value:'rep',align:'right'},
                                {text:'Detalhar',value:'det'}]">
                            <template v-slot:[`item.det`]="{ item }">
                                <v-btn x-small color="primary" @click="comparativo(item)">comparar <v-icon dense class="m-1">mdi-chart-timeline-variant</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>



        <v-dialog v-model="dialogComparativo">
            <v-card>
                <v-btn fab x-small color="secondary" class="m-1" dark @click="dialogComparativo = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text class="text-center">
                    <v-card-title>Comparativo Faturado (KG) - Por {{cidadeEscolhida ? 'Cidade' : UFEscolhida ? 'UF' : 'Região'}} - {{cidadeEscolhida ? cidadeEscolhida : UFEscolhida ? UFEscolhida : regiaoEscolhida}} - {{acumulado?'Acumulado':'Mês a Mês'}} </v-card-title>
                    <small>{{cidadeEscolhida ? cidadeEscolhida : UFEscolhida ? UFEscolhida : regioes[regiaoEscolhida]}}</small>
                    <v-divider></v-divider>
                    
                    <v-btn color="primary" small @click="acumulado=!acumulado">Visão: {{acumulado?'Mês a Mês':'Acumulado'}}

                        <v-icon dense class="m-1">{{acumulado ? 'mdi-chart-bell-curve' : 'mdi-chart-bell-curve-cumulative'}}</v-icon> 
                    </v-btn>
                    
                    <v-card :loading="loadingHistoricos">
                        <v-card-title v-if="historicoRegioes.length == 0">Carregando informações</v-card-title>
                        <LineChartX v-if="historicoRegioes.length > 0" :chart-data="graphDataComputed" :chart-options="chartOptions"/>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { Line as LineChartX } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale,LineElement, PointElement,ArcElement,Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale,PointElement,ArcElement,Filler);
export default {
    components:{
        LineChartX,
    },
    props:{
        token:String
    },
    mounted(){
        this.$nextTick(() =>{
            this.obterDadosPorUF();
            this.obterRelatorioTopCidades();
        })
    },
    computed:{
        regioesComputed(){
            let regioes = [... new Set(this.faturadoUFS.regioes.map(r => r.regiao))];
            
            return regioes.map(r =>{
                let pesliq = this.faturadoUFS.regioes.filter(f => f.regiao == r).reduce((a,x) => a + x.pesliq, 0)
                return {
                    regiao:r,
                    pesliq: Number.parseFloat(pesliq).toLocaleString('pt-br',{maximumFractionDigits:0,minimumFractionDigits:0}),
                    rep: Number.parseFloat((pesliq / (this.faturadoUFS.anual.Peso - this.faturadoUFS.devolvido.xpliq))*100).toFixed(0)
                }
            }); 
        },
        UFSComputed(){
            let ufs = this.faturadoUFS.regioes.map(u => {
                return{
                    Peso: u.Peso,
                    devolucao: u.devolucao,
                    regiao: u.regiao,
                    sigufs: u.sigufs,
                    pesliq: Number.parseFloat(u.pesliq).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}),
                    rep: Number.parseFloat(u.rep).toFixed(0)
                }
            })
            return this.regiaoEscolhida ? ufs.filter(r => r.regiao == this.regiaoEscolhida) : ufs;
            
        },
        graphDataComputed(){
            let labels = ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'];
            
            let acumulado = 0;
            let acumuladoAnoAnterior = 0;
            let acumuladoAnoAnterior2 = 0;
            let datasetMesAMes = [];
            let datasetMesAMesAnoAnterior = [];
            let datasetMesAMesAnoAnterior2 = [];
            let datasetAcumulado = [];
            let datasetAcumuladoAnoAnterior = [];
            let datasetAcumuladoAnoAnterior2 = [];

            let historicoAnoAtual = this.historicoRegioes.filter(h => h.ano == new Date().getFullYear());
            let historicoAnoAnterior = this.historicoRegioes.filter(h => h.ano == new Date().getFullYear() - 1);
            let historicoAnoAnterior2 = this.historicoRegioes.filter(h => h.ano == new Date().getFullYear() - 2);

            for (let i = 1; i <= 12; i++) {
                let mesAtual = historicoAnoAtual.filter(d => d.mes == i);
                if(mesAtual.length > 0){
                    let calc = mesAtual.reduce((a,x) => a + x.pesliq,0);// - mesAtual.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMes.push(calc);
                    acumulado += calc;
                }else{
                    datasetMesAMes.push(0);
                }
                datasetAcumulado.push(acumulado);

                let mesAtualAnoAnterior = historicoAnoAnterior.filter(d => d.mes == i);
                if(mesAtualAnoAnterior.length > 0){
                    let calc = mesAtualAnoAnterior.reduce((a,x) => a + x.pesliq, 0);// - mesAtualAnoAnterior.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMesAnoAnterior.push(calc);
                    acumuladoAnoAnterior += calc;
                }else{
                    datasetMesAMesAnoAnterior.push(0);
                }
                datasetAcumuladoAnoAnterior.push(acumuladoAnoAnterior);

                let mesAtualAnoAnterior2 = historicoAnoAnterior2.filter(d => d.mes == i);
                if(mesAtualAnoAnterior2.length > 0){
                    let calc = mesAtualAnoAnterior2.reduce((a,x) => a + x.pesliq, 0);// - mesAtualAnoAnterior.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMesAnoAnterior2.push(calc);
                    acumuladoAnoAnterior2 += calc;
                }else{
                    datasetMesAMesAnoAnterior2.push(0);
                }
                datasetAcumuladoAnoAnterior2.push(acumuladoAnoAnterior2);

            }

            let datasets = [
                    {
                        backgroundColor: "rgba(1, 116, 188, 0.50)",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        label: new Date().getFullYear(),
                        data: this.acumulado ? datasetAcumulado : datasetMesAMes,
                        fill: true,
                        tension: 0.3
                        // data: []
                    },
                    {
                        backgroundColor: "rgba(188, 116, 1, 0.50)",
                        borderColor: "rgba(188, 116, 1, 0.50)",
                        label:new Date().getFullYear() -1,
                        data: this.acumulado ? datasetAcumuladoAnoAnterior : datasetMesAMesAnoAnterior,
                        fill: true,
                        tension: 0.3
                        // data: []
                    },
                    {
                        backgroundColor: "rgba(188, 1, 1, 0.25)",
                        borderColor: "rgba(188, 1, 1, 0.25)",
                        label:new Date().getFullYear() -2,
                        data: this.acumulado ? datasetAcumuladoAnoAnterior2 : datasetMesAMesAnoAnterior2,
                        fill: true,
                        tension: 0.3
                        // data: []
                    }
                ]

            return {
                acumulado: this.acumulado,
                labels: labels,
                datasets:datasets
            }


        }
    },
    methods:{
        comparativo(value){
            // console.log(value);
            this.historicoRegioes = [];
            this.obterHistoricoRegioes(value);
            this.dialogComparativo = true;
        },
        escolheRegiao(value){

            this.regiaoEscolhida = value.regiao;
            this.UFEscolhida = null;
            this.cidadeEscolhida = null;
        },
        escolheUF(value){
            if(value.sigufs){
                this.loadingCidades = true;
                this.UFEscolhida = value.sigufs;
                this.cidadeEscolhida = null;
                this.$axios.get(`/relatorios/cidade?uf=${value.sigufs}`,{headers:{'Authorization':`Bearer ${this.token}`}})
                .then(resp =>{
                    this.faturadoCidades = resp.data;
    
                    for (let cidade of this.faturadoCidades.cidades) {
                        cidade.devolucao = cidade.devolucao ? Number.parseFloat(cidade.devolucao) : 0;
                        cidade.pesliq = Number.parseFloat(Number.parseFloat(cidade.Peso) - cidade.devolucao).toLocaleString('pt-br',{maximumFractionDigits:0,minimumFractionDigits:0})
                        cidade.rep =  Number.parseFloat((Number.parseFloat(Number.parseFloat(cidade.Peso) - cidade.devolucao) / (this.faturadoCidades.anual.Peso - this.faturadoCidades.devolvido.xpliq))*100).toFixed(0)
                    }
    
                    this.loadingCidades = false;
                    
                }).catch(err => {
                    if(err.response){
                        if(err.response.status == 401){
                            alert('Sessão expirada');
                            sessionStorage.clear();
                            this.$router.push('/login');
                        }
                    }else{
                        alert('Não foi possivel obter as regioes');
                        console.log(err);
                    }
                })
            }
        },
        obterDadosPorUF(){
            this.$axios.get('/relatorios/regiao',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp =>{
                this.faturadoUFS = resp.data;

                for (let UF of this.faturadoUFS.regioes) {
                    UF.regiao = Object.keys(this.regioes).filter(r => this.regioes[r].includes(UF.sigufs))[0]
                    UF.devolucao = UF.devolucao ? Number.parseFloat(UF.devolucao) : 0;
                    UF.pesliq = Number.parseFloat(Number.parseFloat(UF.Peso) - UF.devolucao)//.toLocaleString('pt-br',{maximumFractionDigits:2,minimumFractionDigits:2})
                    UF.rep =  Number.parseFloat((Number.parseFloat(Number.parseFloat(UF.Peso) - UF.devolucao) / (this.faturadoUFS.anual.Peso - this.faturadoUFS.devolvido.xpliq))*100)
                }
            }).catch(err => {
                if(err.response){
                    if(err.response.status == 401){
                        alert('Sessão expirada');
                        sessionStorage.clear();
                        this.$router.push('/login');
                    }
                    }else{
                        alert('Não foi possivel obter as regioes');
                        console.log(err);
                    }
            })
        },
        obterHistoricoRegioes(regiao){
            this.loadingHistoricos = true;

            if(regiao.cidcli){
                let cidade = [regiao.cidcli];
                this.cidadeEscolhida = regiao.cidcli;
                this.$axios.post('/relatorios/cidade',{cidade:cidade},{headers:{'Authorization':`Bearer ${this.token}`}})
                .then(resp =>{
                    this.historicoRegioes = resp.data;
                    this.loadingHistoricos = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loadingHistoricos = false;
                })
            }else{

                let ufs = regiao.sigufs ? [regiao.sigufs] : this.regioes[regiao.regiao];
                
                this.$axios.post('/relatorios/regiao',{ufs:ufs},{headers:{'Authorization':`Bearer ${this.token}`}})
                .then(resp =>{
                    this.historicoRegioes = resp.data;
                    this.loadingHistoricos = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loadingHistoricos = false;
                })
            }
        },
        obterRelatorioTopCidades(){
            this.$axios.get('/relatorios/top-cidades/',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp => {
                this.topCidades = resp.data;
            }).catch(err => {
                console.log(err);
            })
        }
    },
    data(){
        return{
            topCidades:[],
            chartHistory:{},
            chartHistoryAcumulado:{},
            chartOptions:{
                responsive: true, 
                maintainAspectRatio: false,
                showAllTooltips: true,
                legend:{
                    display:true,
                    position:'bottom'
                },
            },
            referenciasUF:{
            AC:'Acre',
            AL:'Alagoas',
            AP:'Amapa',
            AM:'Amazonas',
            BA:'Bahia',
            CE:'Ceara',
            DF:'Distrito Federal',
            ES:'Espirito Santo',
            GO:'Goias',
            MA:'Maranhao',
            MT:'Mato Grosso',
            MS:'Mato Grosso do Sul',
            MG:'Minas Gerais',
            PA:'Para',
            PB:'Paraiba',
            PR:'Parana',
            PE:'Pernambuco',
            PI:'Piaui',
            RJ:'Rio de Janeiro',
            RN:'Rio Grande do Norte',
            RS:'Rio Grande do Sul',
            RO:'Rondonia',
            RR:'Roraima',
            SC:'Santa Catarina',
            SP:'Sao Paulo',
            SE:'Sergipe',
            TO:'Tocantins'
            },
            regioes:{
                sul:['PR','SC','RS'],
                sudeste: ['ES','RJ','SP','MG'],
                norte:['AM','AC','RO','PA','RR','AP','TO'],
                centroOeste:['MT','MS','GO','DF'],
                nordeste:['MA','PI','CE','RN','PB','PE','AL','SE','BA']
            },
            faturadoUFS:{regioes:[],anual:{},devolucao:{}},
            faturadoCidades:{cidades:[],anual:{},devolucao:{}},
            faturadoRegioes:[],
            regiaoEscolhida:null,
            UFEscolhida:null,
            cidadeEscolhida:null,
            loadingCidades:false,
            dialogComparativo:false,
            historicoRegioes:[],
            loadingHistoricos:false,
            acumulado:false,
        }
    }
}
</script>

<style>

</style>