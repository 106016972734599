<template>
    <v-card>
        <v-card-title>Agendamento</v-card-title>
        <v-card-text>
            <v-text-field dense outlined type="date" v-model="agendamento.data" :min="hoje" label="Data Agendamento"></v-text-field>

            <v-textarea dense outlined label="Observação do Agendamento" rows="3" v-model="agendamento.observacao"></v-textarea>
            <v-checkbox v-show="$route.name != 'Atividade'"  v-if="cliente" id="clienteCheck" v-model="agenda.incluirCliente" class="mt-3 mb-3" :label="`Incluir Cliente?\n${agenda.incluirCliente ? 'SIM ✔️' : 'NÃO ❌'}`"> </v-checkbox>
                    
            <FiltroClientes v-if="agenda.incluirCliente && $route.name != 'Atividade'" :cliente="cliente" :clienteFiltrado="clienteFiltrado" @escolheCliente="escolheCliente" />
            <div v-if="agenda.incluirCliente && $route.name == 'Atividade'">
                <span v-if="clienteFiltrado != null"><p><strong>Razão Social:</strong> {{clienteFiltrado.nomcli}}</p></span>
                <span v-if="clienteFiltrado != null"><p><strong>Cidade/UF:</strong> {{clienteFiltrado.cidcli}} - {{clienteFiltrado.sigufs}}</p></span>
            </div>
            <v-btn v-if="$route.name != 'Atividade'" type="button" color="primary" class="mt-3" @click="criarAgendamento()">Agendar</v-btn>

        </v-card-text>

    </v-card>

</template>

<script>
import FiltroClientes from '@/components/FiltroClientes'
export default {
    created(){
        // this.token = this.tkn;
        // let t = sessionStorage.getItem('token');  
        // let dec = JSON.parse(atob(t.split('.')[1]));
        // this.usuario = dec;
        //this.buscarClientes();
        if(this.$route.name == 'Atividade'){
            this.agenda.incluirCliente = true;
        }
    },
    components:{
        FiltroClientes,
    },
    props:['clientes','cliente'],
    data(){
        return{
            //clientes:[],
            //cliente:{},
            usuario:{},
            agendamento:{
                cliente:{
                    codcli:'',
                    nomcli:'',
                },
            },
            agenda:{
                incluirCliente: '',
            },
            clienteBolsao:null,
        }
    },
    methods:{
        criarAgendamento(){
            if(this.agendamento.observacao === ''){
                if(this.$route.name != 'Atividade'){
                    alert('É necessário uma observação')
                }
            }else{
                if(!this.agendamento.data || this.agendamento.data === ''){
                    if(this.$route.name != 'Atividade'){
                        alert('É necessário uma data de agendamento');
                    }
                }else{
                    let agendamento = {};
                    let data = this.agendamento.data;
                    let dataSplit = data.split('-');
                    agendamento.data = new Date(dataSplit[0],dataSplit[1]-1,dataSplit[2]);
                    agendamento.observacao = this.agendamento.observacao;
                    agendamento.cliente = this.agendamento.cliente;
                    
                    this.$axios.post('/atividades/agendamento',{agendamento:agendamento},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
                    .then(() => {
                        alert('Agendamento Incluido: ');
                        this.$emit('criarAgendamento',agendamento);
                        this.agendamento.cliente={codcli:'',nomcli:''};
                        this.agendamento.data = this.hoje;
                        this.agendamento.observacao = '';
                        this.agenda.incluirCliente=false;
                    })
                    .catch(err =>{
                        alert('Erro ao postar Agendamento');
                        console.error(err);
                    })

                }
            }
        },
        buscarClienteBolsao(cnpj){
            this.$axios.get('/clientes/bolsao/'+cnpj,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp => {
                this.clienteBolsao = resp.data;
                console.log('Cliente de Bolsão Identificado')
            })
            .catch(err =>{
                if(err.response.status == 401){
                    sessionStorage.clear();
                    this.$router.push('/');
                    alert('Sessão Expirada');
                }else if(err.response.status == 403){
                    alert('Operação não autorizada')
                }
            })
        },
        
        escolheCliente(cnpj){
            let cliente = this.clientes.find(cliente => cliente.cnpj === cnpj);
            this.$set(this.agendamento,'cliente',cliente);
        },
        
        insereCliente(cliente){
            this.agendamento.cliente = cliente;
        },

        removeCliente(){
            this.agendamento.cliente = {};
        },
    },
    computed:{
        clienteFiltrado(){
            if(this.clientes && this.cliente){
                let clientes = this.clientes;

                //CONFERE SE FOI DIGITADO O CNPJ
                if(this.cliente.cnpj){
                    if(this.cliente.cnpj != ''){
                        clientes = this.clientes.find(c => c.cnpj.startsWith(this.cliente.cnpj));
                        if(clientes){
                            this.insereCliente(clientes);
                            return clientes;
                        }
                        //SE NÃO ENCONTRAR NA LISTA DE CLIENTES BAIXADOS ANTES
                        else{
                            if(this.clienteBolsao){
                                this.insereCliente(this.clienteBolsao);
                                return this.clienteBolsao;
                            }else{
                                this.buscarClienteBolsao(this.cliente.cnpj);
                                this.removeCliente();
                                return null;
                            }

                            
                        }
                    }else{
                        this.removeCliente();
                        return null;
                    }
                }else if(this.cliente.codcli){
                    if(this.cliente.codcli != ''){
                        clientes = this.clientes.find(c => c.codcli == this.cliente.codcli);
                        if(clientes){
                            this.insereCliente(clientes);
                            return clientes;
                        }else{
                            this.removeCliente();
                            return null;
                        }
                    }else{
                        this.removeCliente();
                        return null;
                    }
                }
                else{
                    this.removeCliente();
                    return null;
                }
            }
            else{
                this.removeCliente();
                return null;
            }

        },

        hoje(){
            let data = new Date();
            let strData = `${data.getFullYear()}-${String(data.getMonth()+1).padStart(2,'0')}-${String(data.getDate()).padStart(2,'0')}`
            return strData;
        },

        ontem(){
            let data = new Date();
            let strData = `${data.getFullYear()}-${String(data.getMonth()+1).padStart(2,'0')}-${String(data.getDate()-1).padStart(2,'0')}`
            return strData;
        },
        
        saudacao(){
            let dec = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
            return `Olá ${dec.representacao}`
        },
    },
}
</script>

<style>

</style>