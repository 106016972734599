"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cliente = void 0;
class Cliente {
    constructor(codcli, nomcli, cgccpf, sigufs, cidcli) {
        this.codtpr = ' ';
        this.usu_bensts = '';
        this.codcli = codcli;
        this.nomcli = nomcli;
        this.cgccpf = cgccpf;
        this.sigufs = sigufs;
        this.cidcli = cidcli;
    }
}
exports.Cliente = Cliente;
