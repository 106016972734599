<template>
    <v-card class="d-flex align-items-center m-1 p-1" :loading="loading">
        <v-card-text class="text-center">
        <h4>Faturamento por Representante Dia-a-Dia(KG)</h4>
        <small>Não inclui trocas, amostras e bonificações, <strong>NÃO</strong> abate devoluções</small>
        <v-divider></v-divider>
        <v-btn 
            small
            outlined 
            color="primary" 
            @click="mostrarRepresentantes = !mostrarRepresentantes">{{mostrarRepresentantes ? 'Ocultar':'Mostrar'}} Representantes
            <v-icon dense class="m-1">{{mostrarRepresentantes ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
            </v-btn>


        <div class="tscroll">
            <v-range-slider
            ticks="always"
            thumb-label="always"
            label="Selecione a faixa de dias que deseja ver"
            tick-size="4"
            v-model="dias"
            hint="Selecione a faixa de dias que deseja ver"
            max="31"
            min="1"
            ></v-range-slider>

            <v-simple-table dense>    
            <thead>
                <tr>
                    <th>Representante</th>
                    <th v-for="(d,index) in dias[1]" :key="index" :class="index > 0 ? '' : 'sticky-col' " v-show="index + 1 >= dias[0]">Dia <br>{{index + 1}}</th>
                    <th>Total Representante</th>
                </tr>
            </thead>
            <tbody name="pedidosReps" is="transition-group">
                <tr class="pedidosReps-item" v-show="mostrarRepresentantes" v-for="(r,index) in representantesDistintos" :key="index">
                <td>{{r}}</td>
                <td v-for="x in dias[1]" :key="x" v-show="x >= dias[0]">
                    {{faturadosDia.find(p => p.nomrep == r && p.dia == x) ? Number.parseFloat(faturadosDia.find(p => p.nomrep == r && p.dia == x).Peso).toLocaleString('pt-BR',{minimumFractionDigits:0,maximumFractionDigits:0}) : '-' }}</td>
                <th>{{Number.parseFloat(faturadosDia.filter(p => p.nomrep == r && p.dia >= dias[0] && p.dia &lt;= dias[1]).reduce((a,x) => a + x.Peso,0)).toLocaleString('pt-br',{maximumFractionDigits:2,minimumFractionDigits:2})}}</th>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <th>Totais</th>
                <th v-for="x in dias[1]" :key="x" v-show="x >= dias[0]">{{faturadosDia.find(p => p.dia == x) ? Number.parseFloat(faturadosDia.filter(p => p.dia == x).reduce((a,p) => a + p.Peso, 0)).toLocaleString('pt-BR',{minimumFractionDigits:0,maximumFractionDigits:0}) : '-' }}</th>
                <th>{{Number.parseFloat(faturadosDia.filter(p => p.dia >= dias[0] && p.dia &lt;= dias[1]).reduce((a,x) => a + x.Peso,0)).toLocaleString('pt-br',{maximumFractionDigits:2,minimumFractionDigits:2})}}</th>
                </tr>
            </tfoot>
            </v-simple-table>
        
        </div>

        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:{
        token:String,
    },    
    mounted(){
        this.$nextTick(() => {
           this.obterFaturadosDia();
        })
    },
    data(){
        return{
            mostrarRepresentantes:false,
            loading:false,
            faturadosDia:[],
            dias:[1, new Date(new Date().getFullYear(),new Date().getMonth() + 1, 0).getDate()],
        }
    },
    methods:{
        obterFaturadosDia(){
        this.$axios.get('/relatorios/faturado-dia',{headers:{'Authorization':`Bearer ${this.token}`}})
        .then(resp =>{

            this.faturadosDia = resp.data;
            this.faturadosDia.map(p => {
                p.pesliq = p.devolucao ?  p.Peso - p.devolucao : p.Peso;
                return p;
            })
            this.loading = false;
        })
        .catch(err =>{
            console.log(err);
            alert('Não foi possível buscar pedidos dia a dia');
        })
        },
    },
    computed:{
        representantesDistintos(){
            return [... new Set(this.faturadosDia.map(p => p.nomrep))]
        },
    }
}
</script>

<style scoped>

.tscroll {
  width: 100%;
}

.tscroll table td:first-child,th:first-child {
  position: sticky;
  left: 0;
  /* background-color: #eee; */
}
.pedidosReps-item {
  transition: all .2s ease;
}


</style>