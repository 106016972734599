<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Header />
    <!-- <LoginComponent /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/HeaderComponent.vue'
// import LoginComponent from '@/components/LoginComponent.vue'
export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
    // LoginComponent,
    Header,
  }
}
</script>
