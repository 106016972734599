<template>
    <div class="text-center">
    <div v-if="loading">
        <b-spinner type="grow" label="Loading..."></b-spinner>
    </div>
    <div v-if="consultasFiltradas.length == 0">
        <h3>Nenhuma atividade</h3>
    </div>
    <div v-if="consultasFiltradas.length > 0">

        <v-simple-table dense style="font-size: 0.7rem;">
            <thead>
                <tr class="text-left">
                    <th>Criação</th>
                    <!-- <th>Data Evento</th> -->
                    <th>Nome Rep.</th>
                    <th>Nome Cliente</th>
                    <!-- <th>CNPJ</th> -->
                    <th>Localidade (Cidade/UF)</th>
                    <th>Canal</th>
                    <th>Tipo</th>

                    <th>Peso XB</th>
                    <th>Peso Itaperuna</th>
                    <th>Peso Total</th>
                    <th v-show="usuario.role != 'representante'">Valor</th>
                    <th v-show="usuario.role != 'representante'">Contato</th>
                    <th v-show="usuario.role != 'representante'">Tel. Contato</th>
                    <th>Observacao</th>
                    <!-- <th v-show="usuario.role != 'representante'">Protocolo</th> -->
                    <th v-show="usuario.role">Pedido</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="c in consultasFiltradas" :key="c._id" class="text-left">
                    <td>{{new Date(c.tick).toLocaleDateString('pt-BR',{hour:'2-digit',minute:'2-digit',second:'2-digit'})}}</td>
                    <!-- <td>{{converteDate(c.data).toLocaleDateString('pt-BR')}}</td> -->
                    <td>{{c.nomrep}}</td>
                    <td>
                        <!-- <v-btn small outlined color="primary" @click="mostrarCliente(c._id)">
                            {{c.cliente.nomcli}}
                        </v-btn> -->
                        <a role="button" style="text-decoration:underline" @click="mostrarCliente(c._id)">
                            {{c.cliente.nomcli}}
                        </a>
                    </td>
                    <!-- <td>{{c.cliente.cnpj}}</td> -->
                    <td >{{c.cliente.cidcli}} - {{c.cliente.sigufs}}</td>
                    <td>{{c.canal}}</td>
                    <td>{{c.tipo}}</td>

                    <td><span v-if="c.pesoXB">{{Number(c.pesoXB).toLocaleString('pt-BR')}}</span></td>
                    <td><span v-if="c.pesoItaperuna">{{Number(c.pesoItaperuna).toLocaleString('pt-BR')}}</span></td>
                    <td><span v-if="c.peso">{{Number(c.peso).toLocaleString('pt-BR')}}</span></td>
                    <td v-show="usuario.role != 'representante'"><span v-if="c.valor">{{Number(c.valor).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}}</span></td>
                    <td v-show="usuario.role != 'representante'"><span v-if="c.cliente.nomeContato">{{c.cliente.nomeContato}}</span></td>
                    <td v-show="usuario.role != 'representante'"><span v-if="c.cliente.nomeContato">{{c.cliente.telContato}}</span></td>
                    <td>{{c.observacao}}</td>

                    <!-- <td v-show="usuario.role != 'representante'"><span v-if="c.protocolo">{{c.protocolo}}</span></td> -->
                    <td>
                        <span v-if="c.pedido">
                            <a role="button" @click="mostrarPedido(c.pedido,c)" style="text-decoration:underline">
                                {{c.pedido}}
                            </a>
                        </span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
    <b-modal id="modal-pedido" title="Pedido Cliente" size="xl" hide-footer>
        <ItensPedido ref="pedidos" :clienteFiltrado="clienteFiltrado" :items="items"  />
    </b-modal>
    </div>
</template>

<script>
import ItensPedido from './Itens_Pedido';
export default {
    components:{
        ItensPedido
    },
    data(){
        return{
            loading:false,
            items:[],
            clienteFiltrado:{},
        }
    },
    props:{
        usuario: Object,
        consultasFiltradas: Array
    },
    methods:{
        converteDate(data){
            let d = new Date(data);
            d.setDate(d.getDate()+1);
            return d;
        },
        startLoading(){
            if(this.loading){
                this.loading = false;
            }else{
                this.loading = true;
            }
        },
        mostrarCliente(id){
            this.$emit('mostrarCliente',id);
        },
        mostrarPedido(numped,consulta){
            this.buscarItems(numped,consulta);
            this.clienteFiltrado = consulta.cliente;
            this.$bvModal.show('modal-pedido');
        },
        buscarItems(numped,consulta){
            this.$axios.get(`/pedidos/items/${numped}?codcli=${consulta.cliente.codcli}`,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then(resp =>{
                this.items = resp.data;
            })
            .catch(err =>{
                console.log(err);
            })
        },
        
        alterarAtividade(id){
            this.$axios.post('/atividades/alterar',{id:id},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
            .then((rep) =>{
                this.$emit('recarregarAtividades');
                console.log(rep.data);
                alert('Situação Alterada com sucesso!');
            }).catch(err =>{
                console.log(err);
                alert('Erro ao alterar');
            })
        },
    },
}
</script>

<style>

</style>