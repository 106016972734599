<template>

        <v-card height="100%" class="d-flex align-items-center m-1" :loading="loading">
            <v-card-text class="text-center">
                <h4>Novos Clientes Cadastrados</h4>
                <v-divider></v-divider>
                <div v-if="!loading">
                    <p><strong>{{novosClientes.mesRetrasado.info.mes}}/{{novosClientes.mesRetrasado.info.ano}}</strong>: {{novosClientes.mesRetrasado.dados.length}}</p>
                    <p><strong>{{novosClientes.mesPassado.info.mes}}/{{novosClientes.mesPassado.info.ano}}</strong>: {{novosClientes.mesPassado.dados.length}}</p>
                    <p><strong>{{novosClientes.mesAtual.info.mes}}/{{novosClientes.mesAtual.info.ano}}</strong>: {{novosClientes.mesAtual.dados.length}} </p>
                </div>

                <v-btn color="primary" small @click="dialogs.novosClientes = true">Detalhar
                    <v-icon dense class="m-1">mdi-list-status</v-icon>
                </v-btn>
            </v-card-text>
            
            <v-dialog v-model="dialogs.novosClientes">
                <v-card>
                    <v-btn fab x-small color="secondary" class="m-1" dark @click="dialogs.novosClientes = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-card-text>
                        <v-tabs v-model="tab">
                            <v-tab>
                                Novos Clientes Cadastrados Mês Atual
                            </v-tab>
                            <v-tab>
                                Novos Clientes Cadastrados Mês Passado
                            </v-tab>
                            <v-tab>
                                Novos Clientes Cadastrados Mês Anterior
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Razão Social</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in novosClientes.mesAtual.dados" :key="cliente.CODCLI">
                                            <td>{{ cliente.CODCLI }}</td>
                                            <td>{{ cliente.NOMCLI }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th><th>{{ novosClientes.mesAtual.dados.length }}</th>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-tab-item>

                            <v-tab-item>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Razão Social</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in novosClientes.mesPassado.dados" :key="cliente.CODCLI">
                                            <td>{{ cliente.CODCLI }}</td>
                                            <td>{{ cliente.NOMCLI }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th><th>{{ novosClientes.mesPassado.dados.length }}</th>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-tab-item>

                            <v-tab-item>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Razão Social</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in novosClientes.mesRetrasado.dados" :key="cliente.CODCLI">
                                            <td>{{ cliente.CODCLI }}</td>
                                            <td>{{ cliente.NOMCLI }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th><th>{{ novosClientes.mesRetrasado.dados.length }}</th>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>



</template>

<script>
export default {
    props:{
        token:String
    },
    mounted(){
        this.$nextTick(() =>{
            if(this.token){
                this.obterNovosClientes();
            }
        })
    },
    data(){
        return{
            tab:null,
            novosClientes: {
                mesAtual:{
                    info:[],
                    dados:{},
                },
                mesPassado:{
                    info:[],
                    dados:{},
                },
                mesRetrasado:{
                    info:[],
                    dados:{},
                },
            },
            dialogs:{
                novosClientes:false
            },
            loading:false,
        }
    },
    methods:{
        obterNovosClientes(){
            this.loading = true;
            this.$axios.get('/relatorios/novos-clientes',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp =>{
                this.novosClientes = resp.data;
                this.loading = false;
            })
            .catch(err =>{
                if(err.response){
                    if(err.response.status == 401){
                        alert('Sessão Expirada');
                        sessionStorage.clear();
                        this.$router.push('/login');
                    }else{       
                        alert('Não foi possível buscar novos clientes');
                    }
                }else{
                    alert('Não foi possível buscar novos clientes');
                }
                this.loading = false;
            })
        },
    }
}
</script>

<style>

</style>