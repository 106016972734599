<template>
    <div>
        <div>
            <v-card class="text-left h-100">
                <v-card-title class="text-center">Filtros</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field dense outlined append-icon="mdi-magnify" v-model="clienteData.razaosocial" label="Razão Social" @click:append="buscarRazaoSocial()"></v-text-field>
                            <!-- <b-form-group label="RAZÃO SOCIAL">
                                <b-form-group>
                                <b-row>
                                    <b-col cols="10">
                                        <b-form-input placeholder="RAZÃO SOCIAL" v-model="clienteData.razaosocial"></b-form-input>
                                    </b-col>
                                    <b-col cols="2" style="margin-left:-30px;margin-top:-5px;">
                                        <button type="button" class="btn btn-outline-primary btn-sm mt-2" @click="buscarRazaoSocial()">🔎</button>
                                    </b-col>
                                </b-row>
                                </b-form-group>

                                <div v-if="loading" class="text-center mt-3">
                                    <b-spinner type="grow" label="Loading..."></b-spinner>
                                </div>
                            </b-form-group> -->
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field dense outlined v-model="clienteData.codcli" label="Cód. Cliente"></v-text-field>
                            <!-- <b-form-group label="Cliente">
                                <b-form-input placeholder="Cód Cliente" v-model="clienteData.codcli"></b-form-input>
                            </b-form-group> -->
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field dense outlined v-model="clienteData.cnpj" label="CNPJ" @keypress="isNumber($event)"></v-text-field>
                            <!-- <b-form-group label="CNPJ">
                                <b-form-input placeholder="CNPJ" v-model="clienteData.cnpj" @keypress="isNumber($event)"></b-form-input>
                            </b-form-group> -->
                        </v-col>

                    </v-row>

                    <span v-if="clienteFiltrado != null"><p><strong>Razão Social:</strong> {{clienteFiltrado.nomcli}}</p></span>
                    <span v-if="clienteFiltrado != null"><p><strong>Cidade/UF:</strong> {{clienteFiltrado.cidcli}} - {{clienteFiltrado.sigufs}}</p></span>
                </v-card-text>
            </v-card>
        </div>
        <div>
            <!-- MODAL DE BUSCA RAZAO SOCIAL -->
            <!-- <b-modal id="modalRazao" title="escolha" hide-footer> -->
            <v-dialog v-model="dialogRazao" title="escolha" hide-footer>
                <v-card v-for="cliente in clientesRazao" :key="cliente.codcli">
                    <v-card-text>
                        <p>
                            <strong>Razão Social: </strong> {{cliente.nomcli}} <br>
                            <strong>Cidade/UF: </strong> {{cliente.cidcli}} / {{cliente.sigufs}} <br>
                            <strong>CNPJ: </strong>{{cliente.cnpj}} <br>
                            <strong>Situação: </strong>{{cliente.sitcli == 'I' ? 'Inativo' : 'Ativo'}}
                        </p>
                        <v-btn small color="primary" @click="escolheCliente(cliente.cnpj)">Escolher</v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            cliente:Object,
            clienteFiltrado:Object,
        },
        data(){
            return{
                dialogRazao:false,
                loading:false,
                clientesRazao:[],
                clienteData: {}
            }
        },
        mounted(){
            this.clienteData = this.cliente;
            if(this.$route.query.cnpj){
                this.$set(this.clienteData,'cnpj',this.$route.query.cnpj);
            }
        },
        methods:{
            buscarRazaoSocial(){
                this.loading = true;
                if(this.clienteData.razaosocial && this.clienteData.razaosocial != "" && this.clienteData.razaosocial.length >=3){

                    this.$axios.get('/clientes/razaosocial/'+this.clienteData.razaosocial,{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
                    .then(resp =>{
                        this.clientesRazao = resp.data;
                        // this.$bvModal.show('modalRazao');
                        this.dialogRazao = true;
                    })
                    .catch(err =>{
                        if(err.response.status == 401){
                            sessionStorage.clear();
                            this.$router.push('/');
                            alert('Sessão Expirada');
                        }else{
                            alert('Nenhum cliente válido encontrado!')
                        }
                    })
                }else{
                    if(this.clienteData.razaosocial){

                        if(this.clienteData.razaosocial.length < 3){
                            alert('É necessário um nome com pelo menos 3 caracteres');
                        }
                    }else{
                        alert('É necessário digitar uma razão social antes de buscar')
                    }

                    
                }
                this.loading = false;
            },
            escolheCliente(cnpj){

                this.$set(this.clienteData,'cnpj',cnpj);
                this.$emit('escolheCliente',cnpj);
                // this.clienteFiltrado();
                this.clientesRazao = null;
                this.dialogRazao = false;
                // this.$bvModal.hide('modalRazao');
            },
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
        }
    }
</script>

<style>

</style>