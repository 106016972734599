<template>
  <v-app>
    <nav>     
      <NavBar />
    </nav>
    <router-view/>
  </v-app>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
export default {
  components:{
    NavBar
  },

  created(){
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'pt-br')
  },
  
}
</script>
<style>
@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"), url("~@/assets/fonts/BAHNSCHRIFT.TTF") format("TTF")
  /* url(./assets/BAHNSCHRIFT.TFF) format("truetype"); */
}
</style>
