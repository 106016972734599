"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConcreteSubject = void 0;
class ConcreteSubject {
    constructor() {
        this.state = 0;
        this.faixa = 'FX1';
        this.observers = [];
    }
    attach(observer) {
        const isExist = this.observers.includes(observer);
        if (isExist) {
            return console.error('Subject: Observer has been attached already');
        }

        this.observers.push(observer);
    }
    detach(observer) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex === -1) {
            return console.error(`Subject: Nonexisted observer`);
        }
        this.observers.splice(observerIndex, 1);
    }
    notify(faixa) {
        this.faixa = faixa;
        for (const observer of this.observers) {
            observer.update(this);
        }
    }
}
exports.ConcreteSubject = ConcreteSubject;
