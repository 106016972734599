<template>
<div>
    <v-row dense style="page-break-inside: avoid;">
        <v-col>
            <v-card class="">
                <v-card-text class="text-center">
                <h4>Acompanhamento de faturamento (KG) - Produto Mês a Mês</h4>

                <div class="text-center">
                    <small>Não inclui amostras,trocas e bonificações, abate devoluções</small>
                    <v-divider></v-divider>

                        <v-btn small color="primary" @click="acumulado = !acumulado" class="ml-3">
                            Visão: {{acumulado ? 'Mês a Mês':'Acumulado'}}
                            <v-icon dense class="m-1">{{acumulado ? 'mdi-chart-bell-curve' : 'mdi-chart-bell-curve-cumulative'}}</v-icon> 
                        </v-btn>
                        <v-row class="mt-3">
                            <v-col cols="12" md="3">
                                <v-autocomplete dense outlined @change="obterDadosFaturado()" v-model="escolhidos" label="Escolha aqui o produto desejado" :items="produtos" hint="Escolha os produtos para comparação"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-card flat :loading="loading">
                                    <LineChartX :chart-data="graphDataComputed" :chart-options="chartOptions"/>
                                </v-card>
                            </v-col>
                        </v-row>
                </div>
                </v-card-text>
            </v-card>

            <v-card class="mt-3">
                <v-card-text class="text-center">
                <h4>Comparativo KG entre produtos mês a mês</h4>
                <div class="text-center">
                    <small>Não inclui amostras,trocas e bonificações, abate devoluções</small>
                    <v-divider></v-divider>
                        <v-btn small color="primary" @click="acumulado = !acumulado" class="ml-3">
                            Visão: {{acumulado ? 'Mês a Mês':'Acumulado'}}
                            <v-icon dense class="m-1">{{acumulado ? 'mdi-chart-bell-curve' : 'mdi-chart-bell-curve-cumulative'}}</v-icon> 
                        </v-btn>
                        <v-row class="mt-3">
                            <v-col cols="12" md="3">
                                <v-autocomplete dense outlined multiple v-model="escolhidosMultiplos" label="Escolha aqui os produtos desejados" :items="produtos" hint="Escolha os produtos para comparação"></v-autocomplete>
                                <v-select dense outlined v-model="anoEscolhidoMultiplos" :items="[new Date().getFullYear(), new Date().getFullYear() - 1]" label="Ano"></v-select>
                                <v-btn small color="primary" @click="obterDadosFaturadoMultiplos()" >Obter dados</v-btn>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-card flat :loading="loading">
                                    <LineChartX :chart-data="graphDataComputedMultiplo" :chart-options="chartOptions"/>
                                </v-card>
                            </v-col>
                        </v-row>
                </div>


                <hr>
                </v-card-text>
            </v-card>

            <v-card class="mt-3">
                <v-card-text class="text-center">
                <h4>Maiores Clientes por produto</h4>
                <div class="text-center">
                    <small>Não inclui amostras,trocas e bonificações, abate devoluções</small>
                    <v-divider></v-divider>
                        <v-row class="mt-3">
                            <v-col cols="12" md="3">
                                <v-autocomplete dense outlined v-model="escolhidosMaiores" label="Escolha aqui os produtos desejados" :items="produtos" hint="Escolha o produto"></v-autocomplete>
                                <v-text-field dense outlined type="date" label="Data Inicio" v-model="datasMaiores.inicio" :max="datasMaiores.fim"></v-text-field>
                                <v-text-field dense outlined type="date" label="Data Fim" v-model="datasMaiores.fim" :min="datasMaiores.inicio"></v-text-field>
                                <v-btn small color="primary" @click="obterMaioresClientesProduto()" >Obter dados</v-btn>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-card flat :loading="loading">
                                    <v-data-table dense :headers="[
                                        {text:'Código Cliente',value:'codcli'},
                                        {text:'Razão Social',value:'nomcli'},
                                        {text:'Ramo Atividade',value:'codram'},
                                        {text:'Peso Total',value:'pesliq',align:'right'},
                                    ]" :items="faturadosMaiores">
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                </div>


                <hr>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>

    <!-- TOP 20 PRODUTOS MAIS VENDIDOS -->
    <v-row dense style="page-break-inside: avoid;">
        <v-col>
        <v-card height="100%" class="d-flex align-items-center m-1">
            <v-card-text class="text-center">
            <h4>TOP 20 produtos mais vendidos (KG) Últimos 12 meses</h4>
            <small>Não inclui amostras,trocas e bonificações e contempla os últimos 12 meses. Não inclui mês atual.</small>
                <v-data-table
                    :loading="produtosMaisComprados.length == 0"
                    dense
                    :headers="[
                        {text:'Código Produto',value:'codpro'},
                        {text:'Descrição Produto',value:'despro'},
                        {text:'Peso Líquido (KG)',value:'pesliq', align:'right'},
                        {text:'% Representatividade',value:'rep', align:'right'}]" 
                    :items="produtosMaisComprados.produtos"></v-data-table>
            <hr>
            </v-card-text>
        </v-card>
        </v-col>
    </v-row>




</div>
</template>

<script>
// import { Bubble } from 'vue-chartjs';
// import LineCharts from '../charts/LineCharts'
import { Line as LineChartX } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale,LineElement, PointElement,ArcElement,Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale,PointElement,ArcElement,Filler);

export default {
    components:{
        LineChartX
    },
    props:{
        token:String
    },
    mounted(){
        this.$nextTick(() =>{
            this.obterProdutos();
            this.obterProdutosMaisVendidos();
        })
    },
    data(){
        return{
            produtos:[],
            escolhidos:[],
            escolhidosMultiplos:[],
            escolhidosMaiores:'',
            datasMaiores:{
                inicio:`${new Date().getFullYear()}-01-01`,
                fim:`${new Date().getFullYear()}-01-01`
            },
            anoEscolhidoMultiplos:new Date().getFullYear(),
            acumulado:false,
            loading:false,
            chartOptions:{
                responsive: true, 
                maintainAspectRatio: false,
                showAllTooltips: true,
                legend:{
                    display:true,
                    position:'bottom'
                },
            },
            faturado:[],
            faturadoMultiplos:[],
            faturadosMaiores:[],
            produtosMaisComprados:[],
        }
    },
    methods:{
        obterProdutos(){
            this.$axios.get(`/tabelas/produtos/336`,{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp =>{
                const produtos = resp.data;
                this.produtos = produtos.filter(p => !p.codpro.includes('U')).filter(p => !p.codpro.includes('A')).map(p =>  {
                    return {value:p.codpro,text:`${p.codpro} - ${p.despro}`}
                })
            }).catch(err => {
                alert('Não foi possivel obter os produtos');
                console.log(err);
            })
        },

        obterDadosFaturado(){
            if(this.escolhidos.length > 0){
                
                this.loading = true;
                this.$axios.post('/relatorios/produtos',{produtos:[this.escolhidos]},{headers:{'Authorization':`Bearer ${this.token}`}})
                .then(resp => {
                    this.faturado = resp.data;
                    this.loading = false;
                }).catch(err =>{
                    alert('Não foi possível buscar produtos');
                    console.log(err);
                })
            }else{
                alert('Escolha um produto antes')
            }
        },

        obterDadosFaturadoMultiplos(){
            if(this.escolhidosMultiplos.length > 0){
                if(this.escolhidosMultiplos.length >= 2 && this.escolhidosMultiplos.length <=4){
                    this.loading = true;
                    this.$axios.post('/relatorios/produtos',{produtos:this.escolhidosMultiplos},{headers:{'Authorization':`Bearer ${this.token}`}})
                    .then(resp => {
                        this.faturadoMultiplos = resp.data;
                        this.loading = false;
                    }).catch(err =>{
                        alert('Não foi possível buscar produtos');
                        console.log(err);
                    })
                }else{
                    alert('Escolha entre 2 e 4 proudutos para visualizar este relatório')
                }
            }else{
                alert('Escolha um produto antes')
            }
        },

        obterProdutosMaisVendidos(){
            this.$axios.get('/relatorios/produtos-mais-vendidos',{headers:{'Authorization':`Bearer ${this.token}`}})
            .then(resp => {
                this.produtosMaisComprados = resp.data;
                let devolvido = this.produtosMaisComprados.devolvido ? this.produtosMaisComprados.devolvido.xpliq : 0;
                let pesliq = Number.parseFloat(this.produtosMaisComprados.anual.Peso - devolvido );
                this.produtosMaisComprados.produtos.map(p => {
                    let devolucao = p.devolucao ? p.devolucao : 0;
                    p.pesliq = Number.parseFloat(p.Peso - devolucao).toLocaleString('pt-BR',{minimumFractionDigits:0,maximumFractionDigits:0});
                    p.rep = Number.parseFloat((Number.parseFloat(p.Peso - devolucao) / pesliq)*100).toFixed(2);
                    return p;
                })
            })
            .catch(err => {
                alert('Não foi possível obter os produtos mais comprados');
                console.log(err);
            })
        },

        obterMaioresClientesProduto(){
            if(this.escolhidosMaiores != ''){
                this.loading = true;
                this.$axios.get('/relatorios/maiores-clientes',{params:{codpro:this.escolhidosMaiores,datini:this.datasMaiores.inicio,datfim:this.datasMaiores.fim},headers:{'Authorization':`Bearer ${this.token}`}})
                .then(resp =>{
                    const resposta = resp.data;
                    this.faturadosMaiores = resposta.map(f => {
                        let dev = f.devolucao ? f.devolucao : 0;
                        return {
                            codcli:f.codcli,
                            nomcli: f.nomcli,
                            codram: f.codram,
                            // Peso: f.Peso,
                            // devolucao: dev,
                            pesliq: Number.parseFloat(f.Peso - dev).toLocaleString('pt-br',{minimumFractionDigits:2,maximumFractionDigits:2}),
                        }
                    })
                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                })
            }else{
                alert('Escolha um produto antes');
            }
            // alert('ainda não');
        }
    },
    computed:{
        graphDataComputed(){
            let labels = ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'];
            
            let acumulado = 0;
            let acumuladoAnoAnterior = 0;
            let datasetMesAMes = [];
            let datasetMesAMesAnoAnterior = [];
            let datasetAcumulado = [];
            let datasetAcumuladoAnoAnterior = [];

            let historicoAnoAtual = this.faturado.filter(h => h.ano == new Date().getFullYear());
            let historicoAnoAnterior = this.faturado.filter(h => h.ano == new Date().getFullYear() - 1);

            for (let i = 1; i <= 12; i++) {
                let mesAtual = historicoAnoAtual.filter(d => d.mes == i);
                if(mesAtual.length > 0){
                    let calc = mesAtual.reduce((a,x) => a + x.Peso,0) - mesAtual.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMes.push(calc);
                    acumulado += calc;
                }else{
                    datasetMesAMes.push(0);
                }
                datasetAcumulado.push(acumulado);

                let mesAtualAnoAnterior = historicoAnoAnterior.filter(d => d.mes == i);
                if(mesAtualAnoAnterior.length > 0){
                    let calc = mesAtualAnoAnterior.reduce((a,x) => a + x.Peso, 0) - mesAtualAnoAnterior.reduce((a,x) => a + x.devolucao,0);
                    datasetMesAMesAnoAnterior.push(calc);
                    acumuladoAnoAnterior += calc;
                }else{
                    datasetMesAMesAnoAnterior.push(0);
                }
                datasetAcumuladoAnoAnterior.push(acumuladoAnoAnterior);

            }

            return {
                acumulado: this.acumulado,
                labels: labels,
                datasets:[
                    {
                        backgroundColor: "rgba(1, 116, 188, 0.50)",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        label: new Date().getFullYear(),
                        data: this.acumulado ? datasetAcumulado : datasetMesAMes,
                        fill:true,
                        tension:0.3
                    },
                    {
                        backgroundColor: "rgba(188, 116, 1, 0.50)",
                        borderColor: "rgba(188, 116, 1, 0.50)",
                        label:new Date().getFullYear() -1,
                        data: this.acumulado ? datasetAcumuladoAnoAnterior : datasetMesAMesAnoAnterior,
                        fill:true,
                        tension:0.3
                    }
                ]
            }

        },

        graphDataComputedMultiplo(){
            let labels = ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'];
            
            let datasetMesAMes = [];
            let datasetAcumulado = [];

            //insere na lista datasetMesAMes um objeto e o valor líquido calculado para filtro posterior
            for (const produto of this.escolhidosMultiplos) {
                let acumulado = 0;
                let historicoProduto = this.faturadoMultiplos.filter(h => h.codpro == produto && h.ano == this.anoEscolhidoMultiplos);
                for (let i = 1; i <= 12; i++) {
                    let mesAtual = historicoProduto.filter(d => d.mes == i);                    
                    if(mesAtual.length > 0){
                        let calc = mesAtual.reduce((a,x) => a + x.Peso,0) - mesAtual.reduce((a,x) => a + x.devolucao,0);
                        datasetMesAMes.push({cod:produto,valor:calc});
                        acumulado += calc;
                    }else{
                        datasetMesAMes.push({cod:produto,valor:0});
                    }
                    datasetAcumulado.push({cod:produto,valor:acumulado});
                }
            }

           const randomColor = () => Math.floor(Math.random()*255);

            return {
                acumulado: this.acumulado,
                labels: labels,
                //Para cada produto dentro do array, preciso de um dataset dos dados
                datasets:this.escolhidosMultiplos.map(m => {
                    return{

                        backgroundColor: `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.50)`,
                        borderColor: `rgba(${0}, ${0}, ${0}, 0.50)`,
                        label: m,
                        data: this.acumulado ? datasetAcumulado.filter(d => d.cod == m).map(v => v.valor) : datasetMesAMes.filter(d => d.cod == m).map(v => v.valor),
                        fill:true,
                        tension:0.5
                    }
                })
        
            }

        }

    }
}
</script>

<style>

</style>